import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageComponents.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import Button from '@mui/material/Button';
import axios from 'axios';
import CompComponentDashboard from '../Components/CompComponentDashboard';


const PageComponents = (props) => {

  const [error, setError] = useState(null);

  

  return (
    <CompPageTempMaster3Panel headingOne="Components">

      <CompComponentDashboard />
      
    </CompPageTempMaster3Panel>
  );
}

export default PageComponents;