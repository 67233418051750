import React, { useEffect, useState } from 'react';
import './CompCRUDFormDesignSession.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createDesignSession, updateDesignSession } from '../fxAPI/fxDesignSessionsAPI.js';

const CompCRUDFormDesignSession = (props) => {

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [designSessionResponse, setDesignSessionResponse] = useState(null);
  const [updateDesignSessionName, setUpdateDesignSessionName] = useState(props.currentComponentData.name);
  const [updateDesignSessionDescription, setUpdateDesignSessionDescription] = useState(props.currentComponentData.description);

  console.log('current design session data on form design session: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCreateDesignSession = async (event) => {
    event.preventDefault();
    const related_org = localStorage.getItem("fxLocalOrgID");
    const lead_user = null;
    const participants = null;
    const status = "active";
    const related_anchor = null;
    const related_object_type = null;
    const related_object = null;
    const related_project = props.relatedProject;

    await createDesignSession(name, description, lead_user, participants, status, related_project, related_org, related_anchor, related_object_type, related_object);
  };

  const handleUpdateDesignSessionNameChange = (e) => {
    setUpdateDesignSessionName(e.target.value);
  }

  const handleUpdateDesignSessionDescriptionChange = (e) => {
    setUpdateDesignSessionDescription(e.target.value);
  }

  const handleUpdateDesignSession = async (e) => {
    console.log('ran update design session: ' + props.currentComponentData.design_session_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const designSessionId = props.currentComponentData.design_session_id;
    const updateName = updateName;
    const updateDescription = updateDescription;
    const related_org = relatedOrg;
    const updateLeadUser = null;
    const updateParticipants = null;
    const updateStatus = null;
    const updateRelatedAnchor = null;
    const updateRelatedObjectType = null;
    const updateRelatedObject = null;
    const related_project = props.relatedProject;

  
    await updateDesignSession(designSessionId, updateName, updateDescription, updateLeadUser, updateParticipants, updateStatus, related_project, related_org, updateRelatedAnchor, updateRelatedObjectType, updateRelatedObject);
  };

  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateDesignSession}>
              <div>
                <TextField id="outlined-basic" label="Design Session Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button type="submit">Create Design Session</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Design Session Name</div>
                <div>{props.currentComponentData.name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Description</div>
                <div>{props.currentComponentData.description}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateDesignSession}>
                <div>
                  <TextField id="outlined-basic" label="Design Session Name" variant="outlined" value={updateDesignSessionName} onChange={handleUpdateDesignSessionNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Design Session Description"
                  value={updateDesignSessionDescription}
                  onChange={handleUpdateDesignSessionDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type='submit'>Save Design Session</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormDesignSession;