import axios from 'axios';

const createMilestone = async (name, description, date_start, date_end, related_project, related_org, status) => {
    const setError = (message) => {
      console.error(message);
    };
  
    const setSuccessMessage = (message) => {
      console.log(message);
    };
  
    setError(null);
    setSuccessMessage(null);

    const milestone_id = generateUUID();
  
    if (!name) {
      setError('Name is required');
      return;
    }
  
    try {
      console.log(`Creating new milestone with name: ${name} and related organization: ${related_org}`);
  
      const response = await axios.post('/fxapi/milestones/create', {
        milestone_id,
        name,
        description,
        date_start,
        date_end,
        related_project,
        related_org,
        status
      });
  
      console.log('API response received:', response.data);
      setSuccessMessage('Milestone created successfully');
    } catch (error) {
      console.error('Error creating milestone:', error);
      setError(error.response ? error.response.data : 'Failed to create milestone');
    }
  };

  const updateMilestone  = async (milestoneId, name, description, date_start, date_end, related_project, related_org, status) => {

    try {
      const response = await axios.put(`/fxapi/milestones/update/${milestoneId}`, {
        milestone_id: milestoneId,
        name,
        description,
        date_start,
        date_end,
        related_project,
        related_org,
        status
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const getMilestonesByProjectId = async (projectId, setError, setMilestonesByProjectData) => {
    setError(null);
    setMilestonesByProjectData([]); // Reset Objectives data to an empty array
  
    if (!projectId) {
      setError('ProjectId parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching Milestones data for projectId: ${projectId}`);
      const response = await axios.get('/fxapi/milestones/milestones-by-project', {
        params: { projectId }
      });
      console.log('API response received:', response.data.items);
  
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting milestones data:', items);
      setMilestonesByProjectData(items); // Correctly update the state with received data
      console.log('Updated milestones data:', items);
    } catch (error) {
      console.error('Error fetching milestones data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch milestones data');
    }
  };

  const deleteMilestone = async (milestoneId, setError, setDeleteResponse) => {
    setError(null);
    setDeleteResponse(null); // Reset delete response
  
    if (!milestoneId) {
      setError('Milestone ID parameter is required');
      return;
    }
  
    try {
      console.log(`Deleting milestone with ID: ${milestoneId}`);
      const response = await axios.delete(`/fxapi/milestones/delete/${milestoneId}`);
      console.log('API response received:', response.data);
  
      // Convert response data to plain text
      const successMessage = `Successfully deleted milestone with ID: ${milestoneId}`;
      setDeleteResponse(successMessage); // Update the state with plain text
      console.log('Updated delete response:', successMessage);
    } catch (error) {
      console.error('Error deleting milestone:', error);
  
      // Convert error to plain text
      const errorMessage = error.response ? error.response.data : 'Failed to delete milestone';
      setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
    }
  };

  export { createMilestone, updateMilestone, getMilestonesByProjectId, deleteMilestone };