import React, { useState, useEffect, useContext } from 'react';
import './CompActivityPanel.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompStatusCard from './CompStatusCard';
import { getComStatusTaskByUserId, getComStatusObjectiveByRequestorId, getComStatusObjectiveSubjectiveByRequestorId, getComStatusObjectiveByUserAssignedId, getComStatusObjectiveSubjectiveByUserAssignedId } from '../fxAPI/fxStatusAPI.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CompQuickCreateStatus from './CompQuickCreateStatus.jsx';
import Alert from '@mui/material/Alert';
import { PanelContext } from '../Contexts/PanelContext';
import CompCRUDModal from './CompCRUDModal';
import TriggerActivityHistoryButton from './TriggerActivityHistoryButton';
import TriggerExternalActivity from './TriggerExternalActivity.jsx';


const CompActivityPanel = (props) => {

  const [refreshKey, setRefreshKey] = useState(0);

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const elementId = 'activityPanel';

  // Handle refresh for the specific element
  const handleRefresh = async () => {
    console.log("Refreshing activityPanel data");
    const currentUserId = localStorage.getItem("fxLocalUserID");
    
    // Call your fetch functions here
    await fxAPIFetchComStatusTaskByUserId(currentUserId);
    await fxAPIFetchComStatusObjectiveSubjectiveByUserAssignedId(currentUserId);
    await fxAPIFetchComStatusObjectiveSubjectiveByRequestorData(currentUserId);
  
    resetElementRefresh(elementId); // Reset the refresh flag after refresh is complete
  };

  // Effect to handle refresh logic when the flag changes
  useEffect(() => {
    if (elements[elementId]?.refresh) {
      handleRefresh(); // Trigger the refresh when the flag is set
    }
  }, [elements[elementId]?.refresh]); // Dependency on refresh flag

  const refreshParent = () => {
    /*
    setRefreshKey(prevKey => prevKey + 1); // This will cause a re-render of the parent component
    const currentUserId = localStorage.getItem("fxLocalUserID");
    console.log('activity panel - user: ' + currentUserId);
    fxAPIFetchComStatusTaskByUserId(currentUserId);
    setCurrentUser(currentUserId);
    fxAPIFetchComStatusObjectiveByUserAssignedId(currentUserId);
    fxAPIFetchComStatusObjectiveByRequestorData(currentUserId);
    */
    handleRefresh();
   console.log('test refresh parent');
  };

  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  /* STATUS - TASK BY USER ID */
  const [comStatusDataByUser, setComStatusDataByUser] = useState([]); // Initialize as an array
  /* get objectives by orgId */
  const fxAPIFetchComStatusTaskByUserId  = async (userId) => {
    await getComStatusTaskByUserId(userId, setError, setComStatusDataByUser);
    console.log('all view status task by user Id');
    console.dir(comStatusDataByUser);
  };

 /* STATUS - OBJECTIVE BY USER ASSIGNED ID */
 const [comStatusObjectiveDataByUserAssigned, setComStatusObjectiveByUserAssignData] = useState([]); // Initialize as an array
 /* get status by user assigned */
 const fxAPIFetchComStatusObjectiveByUserAssignedId  = async (userId) => {
   await getComStatusObjectiveByUserAssignedId(userId, setError, setComStatusObjectiveByUserAssignData);
   console.log('all view status objective by user assigned Id');
   console.dir(comStatusObjectiveDataByUserAssigned);
 };

  /* STATUS - OBJECTIVE SUBJECTIVE BY USER ASSIGNED ID */
  const [comStatusObjectiveSubjectiveDataByUserAssigned, setComStatusObjectiveSubjectiveByUserAssignData] = useState([]); // Initialize as an array
  /* get status by user assigned */
  const fxAPIFetchComStatusObjectiveSubjectiveByUserAssignedId  = async (userId) => {
    await getComStatusObjectiveSubjectiveByUserAssignedId(userId, setError, setComStatusObjectiveSubjectiveByUserAssignData);
    console.log('all view status objective by user assigned Id');
    console.dir(comStatusObjectiveSubjectiveDataByUserAssigned);
  };

 /* STATUS - OBJECTIVE BY REQUESTOR ID */
 const [comStatusObjectiveByRequestorData, setComStatusObjectiveByRequestorData] = useState([]); // Initialize as an array
 /* get status by user requestor */
 const fxAPIFetchComStatusObjectiveByRequestorData  = async (userId) => {
   await getComStatusObjectiveByRequestorId(userId, setError, setComStatusObjectiveByRequestorData);
   console.log('all view status objective by user assigned Id');
   console.dir(comStatusObjectiveByRequestorData);
 };

  /* STATUS - OBJECTIVE SUBJECTIVE BY REQUESTOR ID */
  const [comStatusObjectiveSubjectiveByRequestorData, setComStatusObjectiveSubjectiveByRequestorData] = useState([]); // Initialize as an array
  /* get status by user requestor */
  const fxAPIFetchComStatusObjectiveSubjectiveByRequestorData  = async (userId) => {
    await getComStatusObjectiveSubjectiveByRequestorId(userId, setError, setComStatusObjectiveSubjectiveByRequestorData);
    console.log('all view status objective by user assigned Id');
    console.dir(comStatusObjectiveSubjectiveByRequestorData);
  };


  useEffect(() => {
    const currentUserId = localStorage.getItem("fxLocalUserID");
    console.log('activity panel - user: ' + currentUserId);
    fxAPIFetchComStatusTaskByUserId(currentUserId);
    setCurrentUser(currentUserId);
    //fxAPIFetchComStatusObjectiveByUserAssignedId(currentUserId);
    //fxAPIFetchComStatusObjectiveByRequestorData(currentUserId);
    fxAPIFetchComStatusObjectiveSubjectiveByUserAssignedId(currentUserId);
    fxAPIFetchComStatusObjectiveSubjectiveByRequestorData(currentUserId);
    console.dir(currentUserId);
  }, []);

  return (
    <div key={refreshKey} className="fx-comp-activity-panel-wrapper">
        {/* 
    <div className="fx-comp-activity-panel-status-intro-pill-outer">
        <div>Status Request</div>
        <div>Status Updates</div>
    </div>
    */}
    <h2>My Status</h2>
    <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Updates" />
              <Tab label="Requests" />
              <Tab label="Get" />
              
            </Tabs>
            {tabValue === 0 && (
              <Box p={3} className="fx-">
                <Alert severity="info">
                  <div>Your teammates <strong>are asking you</strong> to provide an update on these items:</div>
                </Alert>
                {comStatusObjectiveSubjectiveDataByUserAssigned
                .filter((statusItem) => statusItem.resolved?.trim().toLowerCase() !== "yes")
                .map((statusItem) => (
                  <CompStatusCard
                    key={statusItem.status_id}
                    statusTitle={statusItem.status_view_title}
                    statusDescription={statusItem.status_view_description}
                    statusId={statusItem.status_id}
                    componentData={statusItem}
                    relatedComponentType={statusItem.related_component_type}
                    dateTimeCreated={statusItem.status_view_date_time_created}
                    //refreshParent={refreshParent}
                  />
                ))}
              </Box>
            )}

            {tabValue === 1 && (
              <Box p={3} className="fx-">
                <Alert severity="info">
                  <div><strong>You've asked</strong> your teammates to provide an update on these items:</div>
                </Alert>

                {comStatusObjectiveSubjectiveByRequestorData
                .filter((statusItem) => statusItem.resolved?.trim().toLowerCase() !== "yes")
                .map((statusItem) => (
                  <CompStatusCard
                    key={statusItem.status_id}
                    statusTitle={statusItem.status_view_title}
                    statusDescription={statusItem.status_view_description}
                    statusId={statusItem.status_id}
                    componentData={statusItem}
                    relatedComponentType={statusItem.related_component_type}
                    dateTimeCreated={statusItem.status_view_date_time_created}
                    refreshParent={refreshParent}
                    request="yes"
                  />
                ))}
              </Box>

            )}

            {tabValue === 2 && (
              <>
              <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={"Create Status"} crudMethod={"create"} itemType={"status"} />
              <div className='fx-request-status-outer'>
                <Button variant='outlined' onClick={() => openModal()}>Request Status</Button>
                {/*<TriggerActivityHistoryButton />
                <TriggerExternalActivity />*/}
              </div>
              {/* <CompQuickCreateStatus /> */}
              </>
            )}
    
    </div>
  );
};

export default CompActivityPanel;