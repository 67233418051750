import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageDesignSessionDetail.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import Button from '@mui/material/Button';
import axios from 'axios';
import CompObjectDetail from '../Components/CompObjectDetail';


const PageDesignSessionDetail = (props) => {

  const params = useParams();

  const [error, setError] = useState(null);

  const designSessionId = params.designSessionId;

  return (
    <CompPageTempMaster3Panel headingOne="Design Session">

      {/*Business Value Detail for {testBusinessValue} */}
      <CompObjectDetail detailObjectType="design-session" detailObjectId={designSessionId} />

    </CompPageTempMaster3Panel>
  );
}

export default PageDesignSessionDetail;