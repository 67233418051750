import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';

const CompTickerFeed = () => {
  const [messages, setMessages] = useState([]); // Stores ticker messages
  const [clientMessage, setClientMessage] = useState('Hello from React');
  const socketRef = useRef(null); // Ref to hold the socket instance
  const tickerRef = useRef(null); // Ref for the ticker container

  // Initialize the socket connection once when the component mounts
  useEffect(() => {
    socketRef.current = io('https://www.fluxity.io'); // Connect to the server
  
    // Listen for broadcast messages from the server
    socketRef.current.on('message', (msg) => {
      console.log('Broadcast message received:', msg);
      setMessages((prevMessages) => [...prevMessages, msg]);
    });
  
    return () => {
      socketRef.current.disconnect(); // Clean up on unmount
    };
  }, []);

  // Automatically ping the server every 1 minute
  useEffect(() => {
    const interval = setInterval(() => {
      if (socketRef.current) {
        socketRef.current.emit('client-message', clientMessage); // Send message to the server
        console.log('Pinged server:', clientMessage);
      }
    }, 60 * 1000); // 1 minute

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [clientMessage]);

  // Scroll the ticker to the left when new messages arrive
  useEffect(() => {
    if (tickerRef.current) {
      tickerRef.current.scrollLeft = tickerRef.current.scrollWidth;
    }
  }, [messages]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* 
      <h1>Socket.IO Real-Time Ticker</h1>
      */}
      {/*
      <div
        ref={tickerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          background: '#f1f1f1',
          border: '1px solid #ddd',
          padding: '10px',
        }}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              display: 'inline-block',
              marginRight: '20px',
              padding: '5px 10px',
              background: '#e1e1e1',
              borderRadius: '5px',
              fontSize: '14px',
              whiteSpace: 'nowrap',
            }}
          >
            {msg}
          </div>
        ))}
      </div>
      */}
      {/* 
      <button
        onClick={() => {
          if (socketRef.current) {
            socketRef.current.emit('client-message', clientMessage); // Ping server on demand
            console.log('Manually pinged server:', clientMessage);
          }
        }}
      >
        Manual Ping
      </button>
      */}
    </div>
  );
};

export default CompTickerFeed;
