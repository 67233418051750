import React, { useEffect, useState } from 'react';
import './CompDesignSessionWorkspace.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


const CompDesignSessionWorkspace = (props) => {

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Design Session Workspace" />
              <Tab label="Participants" />
              <Tab label="Add UI Images for Review" />
            </Tabs>
            {tabValue === 0 && (
              <Box p={3} className="fx-">
                Workspace ...
            </Box>
            )}
            {tabValue === 1 && (
              <Box p={3} className="fx-">
                    Participants
              </Box>
            )}
            {tabValue === 2 && (
              <Box p={3} className="fx-">
                    Add UI Images ...
              </Box>
            )}
    </div>
  );
};

export default CompDesignSessionWorkspace;