import React, { useEffect, useState, useContext } from 'react';
import './CompStatusCard.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CompCRUDModal from './CompCRUDModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateStatus } from '../fxAPI/fxStatusAPI';
import CompMiniAvatar from './CompMiniAvatar';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { PanelContext } from '../Contexts/PanelContext';

const CompStatusCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState(false);
  const [modalItemType, setModalItemType] = useState(props.itemType);

  const openModal = (currentModalMode, currentModalItemType) => {
    setModalMode(currentModalMode);
    setModalItemType(currentModalItemType);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const resolveStatus = async (statusId, currentStatus) => {
    console.log('Triggered resolve status of: ' + currentStatus + ' for: ' + statusId);

    try {
      await handleUpdateStatus(currentStatus);
      console.log('Status updated successfully!');
      //handleRefreshElement();
    } catch (error) {
      console.error('Error updating status:', error);

      if (error.message.includes('Parse Error')) {
        console.error('Specific Parse Error occurred:', error);
      }
    } finally {
      // Ensure that the parent is refreshed, regardless of the error
      //props.refreshParent();
      handleRefreshElement();
    }
  };

  const handleUpdateStatus = async (statusCurrent) => {
    const statusId = props.statusId;
    const title = null;
    const description = null;
    const related_org = null;
    const status_current = statusCurrent;
    const resolved = 'yes';

    try {
      // Simulate the API call to Oracle ADB
      await updateStatus(statusId, title, status_current, description, related_org, resolved);
      //handleRefreshElement();
    } catch (error) {
      throw new Error(`Failed to update status: ${error.message}`);
    }
  };

  const modalTitleCompPortion = "Status";

  console.log('Status card component data1: ');
  console.dir(props.componentData);

  /* Use Global Context to refresh Subjective Panel */
  const { triggerElementRefresh } = useContext(PanelContext);

  const handleRefreshElement = () => {
    console.log("Triggering refresh for activityPanel");
    triggerElementRefresh("activityPanel");
  };

  return (
    <>
      <CompCRUDModal
        isOpen={isOpen}
        onClose={closeModal}
        modalTitle={modalTitleCompPortion + ": " + props.statusTitle}
        modalComponentData={props.componentData}
        itemType="status"
        crudMethod={modalMode}
      />
      <div className="fx-comp-status-card-outer">
        {props.componentData.resolved === "yes" && <div>Resolved!</div>}
        
        <div className="fx-comp-status-card-users-row">
          <div className="fx-comp-status-card-users-row-item-avatars">
            <CompMiniAvatar userId={props.componentData.requestor} /><TrendingFlatIcon /><CompMiniAvatar userId={props.componentData.user_assigned} />
          </div>
        </div>
        <div className="fx-comp-status-card-title-line"><strong>{props.statusTitle}</strong></div>
        <div className="fx-comp-status-card-description-line">{props.statusDescription}</div>
        <div className='fx-comp-status-card-action-pills-outer'>
          {props.request !== "yes" &&
            <>
              <Chip className="fx-comp-status-action-pill-done" label="Done" onClick={() => resolveStatus(props.statusId, "Done")} />
              <Chip className="fx-comp-status-action-pill-working" label="Working" onClick={() => resolveStatus(props.statusId, "Working")} />
            </>
          }
        </div>
        <br />
        <div className='fx-comp-status-card-subtext'>{props.relatedComponentType}</div>
        <div className='fx-comp-status-card-subtext'>{props.dateTimeCreated}</div>
      </div>
    </>
  );
};

export default CompStatusCard;