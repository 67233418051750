// src/Contexts/ActivityHistoryContext.js

import React, { createContext, useContext, useState } from 'react';
import { getSocket } from '../Utilities/socket'; // Import the socket utility
import { createActivityHistory as apiCreateActivityHistory } from '../fxAPI/fxActivityHistoryAPI'; // Import your API call

const ActivityHistoryContext = createContext();

export const useActivityHistory = () => useContext(ActivityHistoryContext);

export const ActivityHistoryProvider = ({ children }) => {
  const [activityHistory, setActivityHistory] = useState([]);
  const socket = getSocket();

  // Function to create activity history, commit to DB, and emit via socket
  const createActivityHistory = async (message, relatedOrg, related_object_type, related_object, authoring_user, source) => {
    // Commit to the DB
    const newActivity = await apiCreateActivityHistory(
      message,
      related_object_type,
      related_object,
      null, // category
      null, // tags
      null, // metadata
      relatedOrg,
      null, // related_project
      authoring_user,
      source,
      socket,
      true // Emit event
    );

    // Emit the new activity to real-time clients
    socket.emit('activity-history-created', newActivity.data);

    // Optionally update local state with the new activity
    setActivityHistory((prevHistory) => [newActivity.data, ...prevHistory]);
  };

  return (
    <ActivityHistoryContext.Provider value={{ activityHistory, createActivityHistory }}>
      {children}
    </ActivityHistoryContext.Provider>
  );
};

