import React, { useEffect, useState } from 'react';
import './CompCRUDFormUIAsset.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import CompFileUpload from './CompFileUpload';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createUIAsset, updateUIAsset } from '../fxAPI/fxUIAssetsAPI.js';
import Resizer from 'react-image-file-resizer';
import Dropzone from 'react-dropzone';
import axios from 'axios';

const CompCRUDFormUIAsset = (props) => {

  const [name, setName] = useState('');
  //const [imgFull, setImgFull] = useState('');
  //const [imgThumb, setImgThumb] = useState('');
  //const [version, setVersion] = useState('');
  const [error, setError] = useState(null);
  //const [relatedProject, setRelatedProject] = useState(null);
  const [uiAssetResponse, setUIAssetResponse] = useState(null);

  console.log('current project data on form ui asset: ');
  console.dir(props.currentComponentData);


  /*
  const handleCreateUIAsset = (event) => {
    event.preventDefault();
    // set temporary defaults:
    const imgFull = thumbnail; // from saved full
    const imgThumb = fullImage; // from saved thumb
    const version = 'version here';
    const relatedProject = '12345';

    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    createUIAsset(relatedOrg, relatedProject, name, imgFull, imgThumb, version, setUIAssetResponse);
  };
 */

  const [currentEditableState, setCurrentEditableState] = useState('view');

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [uiAssetName, setUIAssetName] = useState('');

  const handleFileChange = (event) => {
    setImageFile(event.target.files[0]);
    console.log('set image file test: ');
    console.dir(event.target.files[0]);
  };

  const handleCreateUIAsset = (currentImgUrl) => {
    console.log('current image url: ' + currentImgUrl);
    const imgFull = currentImgUrl; // from saved full
    const imgThumbPrefix = currentImgUrl.replace("/public", "");
    const imgThumb = imgThumbPrefix + "/w=200,h=200,fit=crop,crop=centered";
    const version = 'version here';
    const relatedProject = props.relatedProject;
    const name = uiAssetName;

    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    // Assuming createUIAsset is an imported function
    createUIAsset(relatedOrg, relatedProject, name, imgFull, imgThumb, version, setUIAssetResponse);
};

const handleUpload = async () => {
    if (!imageFile) return;
  
    const formData = new FormData();
    formData.append('file', imageFile);
  
    try {
        const response = await fetch('https://fluxity.io/fxapi/upload-image', {
            method: 'POST',
            body: formData,
        });
  
        if (!response.ok) {
            throw new Error(`Failed to upload image: ${response.statusText}`);
        }
  
        const data = await response.json();
        console.log('Server Response:', data);
  
        if (data && data.result && data.result.variants) {
            const cdnUrl = data.result.variants[0];
            setImageUrl(cdnUrl);

            // Call handleCreateUIAsset after the imageUrl is set
            handleCreateUIAsset(cdnUrl);
        } else {
            throw new Error('Invalid response structure or upload failed');
        }
    } catch (error) {
        console.error('Error uploading image:', error);
    }
};

const handleUpdateUIAsset = async (e) => {
  console.log('ran update ui asset: ' + props.currentComponentData.ui_asset_id);
  e.preventDefault();
  const currentOrgId = localStorage.getItem("fxLocalOrgID");

  const uiAssetId = props.currentComponentData.ui_asset_id;
  const name = 'Updated UI Asset Name API';
  const imgFullUrl = '...';
  //const related_org = '12345';

  await updateUIAsset(uiAssetId, name, imgFullUrl, currentOrgId);
};

  const handleUIAssetNameChange = async (e) => {
    setUIAssetName(e.target.value);
  };

  return (
    <div>
        {props.crudMethod === "create" &&
        
        <div>
              <div>
                <input type="file" onChange={handleFileChange} />
                <br />
                <TextField id="outlined-basic" label="UI Asset Name" variant="outlined" onChange={handleUIAssetNameChange} />
                <br />
                <Button onClick={handleUpload}>Upload</Button>
                {imageUrl && <img width="200px" height="auto" src={imageUrl} alt="Uploaded" />}
              </div>
            <br />
                {/* <TextField id="outlined-basic" label="UI Asset" variant="outlined" onChange={(e) => setName(e.target.value)} />*/}
              <CompModalButtons>
                <Button type="submit">Create UI Asset</Button>
              </CompModalButtons>         
        </div>
        }

        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div>UI Asset Name</div>
                <div>(UI Asset Name)</div>
              </div> 
              <div>
                <div>UI Asset Description</div>
                <div>(UI Asset Description)</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateUIAsset}>
                <div>
                  <TextField id="outlined-basic" label="UI Asset Name" variant="outlined" />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="UI Asset Description"
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type="submit">Save UI Asset</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormUIAsset;