import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import './PagePlaybookObjectiveStack.css';
import CompTeamMemberList from '../Components/CompTeamMemberList';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompObjectiveStack from '../Components/CompObjectiveStack';

const PagePlaybookObjectiveStack = (props) => {
  
    const params = useParams();

  return (
    <CompPageTempMaster3Panel headingOne="Objective Stack">

      <CompObjectiveStack relatedProject={params.projectId} /> 
   
    </CompPageTempMaster3Panel>
  );
};

export default PagePlaybookObjectiveStack;