import React, { useEffect, useState } from 'react';
import './PageAuthCallback.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const PageAuthCallback = () => {
  const { handleRedirectCallback, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const completeAuth = async () => {
      try {
        // Complete the login process
        await handleRedirectCallback();
        // Redirect to a default route or the user's intended destination
        navigate('/'); // Replace '/dashboard' with your desired route
      } catch (err) {
        console.error('Error handling redirect callback', err);
      }
    };

    completeAuth();
  }, [handleRedirectCallback, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <div>Redirecting...</div>; // Optional loading UI while redirecting
};

export default PageAuthCallback;