import React, { useState, useEffect, useContext } from 'react';
import './CompQuickCreateObjective.css';
import { Box, Button, TextField } from '@mui/material';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI';
import { createSubjective } from '../fxAPI/fxSubjectivesAPI.js';
import { PanelContext } from '../Contexts/PanelContext';

const CompQuickCreateSubjective = (props) => {
  const [error, setError] = useState(null);
  const [newSubjectiveName, setNewSubjectiveName] = useState('');
  const [newSubjectiveDescription, setNewSubjectiveDescription] = useState('');
  const { triggerElementRefresh } = useContext(PanelContext);

  // Fetch projects by org ID (if needed for dropdowns or other logic)
  useEffect(() => {
    const currentOrgId = localStorage.getItem('fxLocalOrgID');
    if (currentOrgId) {
      getProjectsByOrgId(currentOrgId, setError, () => {});
    }
  }, []);

  const handleSubjectiveNameChange = (e) => setNewSubjectiveName(e.target.value);
  const handleSubjectiveDescriptionChange = (e) => setNewSubjectiveDescription(e.target.value);

  const handleCreateSubjective = async (event) => {
    event.preventDefault();

    const related_org = localStorage.getItem('fxLocalOrgID');
    const { relatedObjective, relatedProject } = props;

    console.log('Creating subjective with:', {
      name: newSubjectiveName,
      description: newSubjectiveDescription,
      related_org,
      relatedObjective,
      relatedProject,
    });

    try {
      await createSubjective(
        newSubjectiveName,
        newSubjectiveDescription,
        related_org,
        relatedObjective,
        relatedProject
      );
      console.log('Subjective created successfully!');

      // Trigger parent refresh
      triggerElementRefresh('subjectivePanel');

      // Clear inputs after successful creation
      setNewSubjectiveName('');
      setNewSubjectiveDescription('');
    } catch (err) {
      console.error('Error creating subjective:', err);
      setError(err.message || 'Error creating subjective');
    }
  };

  return (
    <div>
      <Box p={3}>
        <form onSubmit={handleCreateSubjective}>
          <div className="fx-comp-quick-create-subjective-fields-row">
            {/* Title Input */}
            <TextField
              id="subjective-title"
              label="Title"
              variant="outlined"
              value={newSubjectiveName}
              onChange={handleSubjectiveNameChange}
              fullWidth
            />
            <br />
            <br />

            {/* Description Input */}
            <TextField
              id="subjective-description"
              label="Description"
              multiline
              maxRows={4}
              value={newSubjectiveDescription}
              onChange={handleSubjectiveDescriptionChange}
              fullWidth
            />
            <br />
            <br />

            {/* Submit Button */}
            <Button variant="outlined" type="submit">
              Create Subjective
            </Button>
          </div>
        </form>
        {error && <p className="error-message">{error}</p>}
      </Box>
    </div>
  );
};

export default CompQuickCreateSubjective;
