import React from 'react';
import './CompHelpBanner.css';
import { Box, Typography, Button } from '@mui/material';
import Alert from '@mui/material/Alert';


const CompHelpBanner = (props) => {

  return (
    <div>
        <Box>
        <Alert severity="info">{props.bannerMessage}</Alert>
        </Box>
    </div>
  );
};

export default CompHelpBanner;