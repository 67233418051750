import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageBusinessValueDetail.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import Button from '@mui/material/Button';
import axios from 'axios';
import CompBusinessValueBreakdown from '../Components/CompBusinessValueBreakdown';
import CompObjectDetail from '../Components/CompObjectDetail';


const PageBusinessValueDetail = (props) => {

  const params = useParams();

  const [error, setError] = useState(null);

  const testBusinessValue = params.businessValueId;

  return (
    <CompPageTempMaster3Panel headingOne="Business Value Detail">

      {/*Business Value Detail for {testBusinessValue} */}
      <CompObjectDetail detailObjectType="business-value" detailObjectId={testBusinessValue} />

    </CompPageTempMaster3Panel>
  );
}

export default PageBusinessValueDetail;