import React, { useEffect, useState, useContext } from 'react';
import './CompCRUDModal.css'; // Create this CSS file for styling
import CompCRUDFormUserPersona from './CompCRUDFormUserPersona';
import CompCRUDFormTask from './CompCRUDFormTask';
import CompCRUDFormStatus from './CompCRUDFormStatus';
import CompCRUDFormUIAsset from './CompCRUDFormUIAsset';
import CompCRUDFormTeamMember from './CompCRUDFormTeamMember';
import CompCRUDFormObjective from './CompCRUDFormObjective';
import CompCRUDFormProject from './CompCRUDFormProject';
import CompCRUDFormSubjective from './CompCRUDFormSubjective.jsx';
import CompCRUDFormPath from './CompCRUDFormPath.jsx';
import CompCRUDFormObjectLink from './CompCRUDFormObjectLink.jsx';
import CompCRUDFormMilestone from './CompCRUDFormMilestone.jsx';
import CompCRUDFormBusinessValue from './CompCRUDFormBusinessValue.jsx';
import CompCRUDFormDesignSession from './CompCRUDFormDesignSession.jsx';
import CompCRUDFormComponent from './CompCRUDFormComponent.jsx';

import Alert from '@mui/material/Alert';
import { Button } from '@mui/material';
import { deleteProject } from '../fxAPI/fxProjectsAPI.js';
import { deleteObjective } from '../fxAPI/fxObjectivesAPI.js';
import { deleteSubjective } from '../fxAPI/fxSubjectivesAPI.js';
import { deleteTask } from '../fxAPI/fxTasksAPI.js';
import { deleteStatus } from '../fxAPI/fxStatusAPI.js';
import { deleteUIAsset } from '../fxAPI/fxUIAssetsAPI.js';
import { deleteUserPersona } from '../fxAPI/fxUserPersonasAPI.js';
import { deleteUser } from '../fxAPI/fxUsersAPI.js';
import { deletePath } from '../fxAPI/fxPathsAPI.js';
import { deleteMilestone } from '../fxAPI/fxMilestonesAPI.js';
import { deleteBusinessValue } from '../fxAPI/fxBusinessValueAPI.js';
import { deleteDesignSession } from '../fxAPI/fxDesignSessionsAPI.js';
import { deleteComponent } from '../fxAPI/fxComponentsAPI.js';
import { PanelContext } from '../Contexts/PanelContext';
import CompQuickCreateStatus from './CompQuickCreateStatus.jsx';


const CompCRUDModal = ({ isOpen, onClose, children, modalTitle, modalComponentData, itemType, crudMethod, relatedComponentId, relatedComponentType, relatedProject, relatedComponentName, relatedAnchorId, linkType, closeModal }) => {
  const [error, setError] = useState(null);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const { triggerElementRefresh } = useContext(PanelContext);

  /* use global context */
  /*
  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const elementId = 'subjectivePanel';

  // Handle refresh for the specific element
  const handleRefresh = async () => {
    console.log('Refreshing subjective panel data');
    resetElementRefresh(elementId); // Reset the refresh flag after completion
  };
  */

  const handleClose = (refreshTarget) => {
    closeModal();  // This will close the modal
    if(refreshTarget) {
      console.log('remote close called with refresh target: ' + refreshTarget);
    } else {
      console.log('remote close without refresh target');
    }
    //handleRefresh(); // Trigger the refresh only when needed
  };
  

  if (!isOpen) return null; // Early return if modal is not open

  console.log('Component data on modal:');
  console.dir(modalComponentData);
  /*
  if (itemType === "project") {
    console.log('project_id: ' + modalComponentData.project_id); 
  }
  */

  const deleteObject = async (e, objectType, objectTypeData) => {
    e.preventDefault(); // Prevent default action (form submission, etc.)

    if (objectType === "project") {
      const projectId = objectTypeData.project_id;
      try {
        await deleteProject(projectId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete project'); // Handle error gracefully
      }
    }

    if (objectType === "objective") {
      const objectiveId = objectTypeData.objective_id;
      try {
        await deleteObjective(objectiveId, setError, setDeleteResponse);
        handleClose();

        triggerElementRefresh('objectiveAnchorCard'); 
        
      } catch (err) {
        setError('Failed to delete objective'); // Handle error gracefully
      }
    }

    if (objectType === "subjective") {
      const subjectiveId = objectTypeData.subjective_id;
      try {
        await deleteSubjective(subjectiveId, setError, setDeleteResponse);
        handleClose();
        
        triggerElementRefresh('subjectivePanel'); 
      } catch (err) {
        setError('Failed to delete subjective'); // Handle error gracefully
      }
    }

    if (objectType === "task") {
      const taskId = objectTypeData.task_id;
      try {
        await deleteTask(taskId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete task'); // Handle error gracefully
      }
    }

    if (objectType === "status") {
      const statusId = objectTypeData.status_id;
      try {
        await deleteStatus(statusId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete status'); // Handle error gracefully
      }
    }

    if (objectType === "ui-asset") {
      const uiAssetId = objectTypeData.ui_asset_id;
      try {
        await deleteUIAsset(uiAssetId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete ui asset'); // Handle error gracefully
      }
    }

    if (objectType === "user-persona") {
      const userPersonaId = objectTypeData.user_persona_id;
      try {
        await deleteUserPersona(userPersonaId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete user persona'); // Handle error gracefully
      }
    }

    if (objectType === "team-member") {
      const userId = objectTypeData.user_id;
      try {
        await deleteUser(userId, setError, setDeleteResponse);
        handleClose();
        console.log('attempt refresh on team list from CRUD Modal');
        triggerElementRefresh('teamList'); 
      } catch (err) {
        setError('Failed to delete user'); // Handle error gracefully
      }
    }

    if (objectType === "path") {
      const pathId = objectTypeData.path_id;
      try {
        await deletePath(pathId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete user'); // Handle error gracefully
      }
    }

    if (objectType === "milestone") {
      const milestoneId = objectTypeData.milestone_id;
      try {
        await deleteMilestone(milestoneId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete milestone'); // Handle error gracefully
      }
    }

    if (objectType === "business-value") {
      const businessValueId = objectTypeData.business_value_id;
      try {
        await deleteBusinessValue(businessValueId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete business value'); // Handle error gracefully
      }
    }

    if (objectType === "design-session") {
      const designSessionId = objectTypeData.design_session_id;
      try {
        await deleteDesignSession(designSessionId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete design session'); // Handle error gracefully
      }
    }

    if (objectType === "component") {
      const componentId = objectTypeData.component_id;
      try {
        await deleteComponent(componentId, setError, setDeleteResponse);
      } catch (err) {
        setError('Failed to delete component'); // Handle error gracefully
      }
    }

  };

  function modalTitle() {
    /* --- Set modal titles --- */
    if(itemType === "objective") {
      var suffix = "Objective";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "subjective") {
      var suffix = "Subjective";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "status") {
      var suffix = "Status";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "project") {
      var suffix = "Playbook";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "user-persona") {
      var suffix = "User Persona";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "ui-asset") {
      var suffix = "UI Asset";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "object-link" && linkType === "anchor-path") {
      var suffix = "Link of Step to Path";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "team-member") {
      var suffix = "Team Member";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "milestone") {
      var suffix = "Milestone";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "business-value") {
      var suffix = "Business Value";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "design-session") {
      var suffix = "Design Session";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    if(itemType === "component") {
      var suffix = "Component";
    if(crudMethod === "view-edit") {
      var prefix = "View";
    } else if(crudMethod === "create") {
      var prefix = "Create";
    } else if(crudMethod === "delete") {
      var prefix = "Delete";
    }
      var modalTitleCombo = prefix + ' ' + suffix;
    }

    return modalTitleCombo;
  }

  const modalCleanTitle = modalTitle();

  



  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>

        <button className="modal-close" onClick={onClose}>X</button>

        <div>
          {error && (
            <div className="error">
              {error}
            </div>
          )}
          {deleteResponse && (
            <div className="delete-response">
              {deleteResponse}
            </div>
          )}
        </div>

        <h2>{modalCleanTitle}</h2>
        <div className="uxc-debug">
          Component ID: {relatedComponentId}
          Item Type: {itemType} CRUD Method: {crudMethod}
          Related Project: {relatedProject}
        </div>
        {/*{children */}
        

        {/* User Persona Views */}
        { itemType === "user-persona" &&
          <CompCRUDFormUserPersona crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Task Views */}
        { itemType === "task" &&
          <CompCRUDFormTask crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Status Views */}
        { itemType === "status" &&
          <CompQuickCreateStatus relatedComponentId={relatedComponentId} itemType={itemType} relatedComponentName={relatedComponentName} relatedComponentType={relatedComponentType} modalTitle={"Status"} />
        }

        {/* UI Asset Views */}
        { itemType === "ui-asset" &&
          <CompCRUDFormUIAsset crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedProject={relatedProject} />
        }

        {/* Team Member Views */}
        { itemType === "team-member" &&
          <CompCRUDFormTeamMember crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} handleClose={handleClose} />
        }

        {/* Objective Views */}
        { itemType === "objective" &&
          <CompCRUDFormObjective crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedAnchorId={relatedAnchorId} />
        }

        {/* Subjective Views */}
        { itemType === "subjective" &&
          <CompCRUDFormSubjective crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Project Views */}
        { itemType === "project" &&
          <CompCRUDFormProject crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} />
        }

        {/* Path Views */}
        { itemType === "path" &&
          <CompCRUDFormPath crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedProject={relatedProject} />
        }

        {/* Path Views */}
        { itemType === "object-link" &&
          <CompCRUDFormObjectLink crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedProject={relatedProject} linkType={linkType} relatedAnchorId={relatedAnchorId} />
        }

        {/* Milestone Views */}
        { itemType === "milestone" &&
          <CompCRUDFormMilestone crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedProject={relatedProject} />
        }

        {/* Business Value Views */}
        { itemType === "business-value" &&
          <CompCRUDFormBusinessValue crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedProject={relatedProject} />
        }

        {/* Design Session Views */}
        { itemType === "design-session" &&
          <CompCRUDFormDesignSession crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedProject={relatedProject} />
        }

        {/* Component */}
        { itemType === "component" &&
          <CompCRUDFormComponent crudMethod={crudMethod} currentComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedProject={relatedProject} />
        }

        {crudMethod === "delete" &&
            <div>
              {/*
                {error && <div className="error">{error}</div>}
                {deleteResponse && <div className="success">{deleteResponse.message}</div>}
              */}
              <Alert severity="info"><div className='fx-comp-crud-modal-delete-row'><div>Confirm delete?</div> <div><Button onClick={(e) => deleteObject(e, itemType, modalComponentData)}>Delete</Button></div></div></Alert>
            </div>
        }


      </div>
    </div>
  );
};

export default CompCRUDModal;