import React, { useState, useEffect } from 'react';
import './CompUIAssetBulkProcessor.css';
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  TextField,
  Select,
  MenuItem,
  Button,
  Collapse,
} from '@mui/material';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { createUIAsset } from '../fxAPI/fxUIAssetsAPI.js';
import { getComponentsByOrgId, updateComponent } from '../fxAPI/fxComponentsAPI.js';
import { getAnchorsObjectivesByProjectId, updateAnchor } from '../fxAPI/fxAnchorsAPI.js';
import CompCollapsibleContainer from './CompCollapsibleContainer.jsx';

const CompUIAssetBulkProcessor = (props) => {

  const [error, setError] = useState(null);

  const [imageUrl, setImageUrl] = useState('');
  const [uiAssetResponse, setUIAssetResponse] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

/* PROJECTS */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  /* COMPONENTS */
  const [componentsByOrgId, setComponentsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchComponentsByOrgId = async (orgId) => {
    await getComponentsByOrgId(orgId, setError, setComponentsByOrgData);
    console.log('all components by org Id');
    console.dir(componentsByOrgId);
  };

  /* ANCHORS */
  const [anchorsObjectivesByProjectId, setAnchorsObjectivesByProjectData] = useState([]); // Initialize as an array
  /* get anchors objectives by project */
  const fxAPIFetchAnchorsObjectivesByProjectId = async (projectId) => {
    await getAnchorsObjectivesByProjectId(projectId, setError, setAnchorsObjectivesByProjectData);
    console.log('all anchors objectives by project Id');
    console.dir(anchorsObjectivesByProjectId);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith('image/')
    );
    const images = files.map((file) => ({
      id: URL.createObjectURL(file),
      file,
      title: '',
      project: '',
    }));
    setUploadedImages(images);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTitleChange = (id, newTitle) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, title: newTitle } : img
      )
    );
  };

  const handleProjectChange = (id, newProject) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, project: newProject } : img
      )
    );
  };
  
  const handleAnchorChange = (id, newAnchor) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, anchor: newAnchor } : img
      )
    );
  };

  const handleComponentChange = (id, newComponent) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, component: newComponent } : img
      )
    );
  };

  const handleAddImage = async (id) => {
    const image = uploadedImages.find((img) => img.id === id);
    if (!image || !image.file) {
      console.error('No valid image file found for the provided ID');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', image.file);
  
    try {
      const response = await fetch('https://fluxity.io/fxapi/upload-image', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.statusText}`);
      }
  
      const data = await response.json();
      const cdnUrl = data?.result?.variants?.[0];
      if (!cdnUrl) {
        throw new Error('Invalid response structure or upload failed');
      }
  
      await handleCreateUIAsset(cdnUrl, image.title, image.project || image.component || image.anchor);
    } catch (error) {
      console.error('Error uploading or processing image:', error);
    }
  };
  

const handleCreateUIAsset = async (currentImgUrl, imageTitle, imageRelatedObject) => {
  console.log('current image url: ' + currentImgUrl);
  const imgFull = currentImgUrl;
  const imgThumb = `${currentImgUrl.replace("/public", "")}/w=200,h=200,fit=crop,crop=centered`;
  const version = 'version here';
  const relatedOrg = localStorage.getItem("fxLocalOrgID");
  const relatedProject = props.context === "default" ? imageRelatedObject : null;
  const name = imageTitle;

  try {
    // Create UI Asset
    await createUIAsset(
      relatedOrg,
      relatedProject,
      name,
      imgFull,
      imgThumb,
      version,
      setUIAssetResponse
    );

    if (props.context === "component") {
      // Update the Component only if context is "component"
      await handleUpdateComponent(imageRelatedObject, currentImgUrl);
    }

    if (props.context === "anchor") {
      // Update the Anchor only if context is "anchor"
      await handleUpdateAnchor(imageRelatedObject, currentImgUrl);
    }
  } catch (error) {
    console.error('Error creating UI asset or updating component:', error);
  }
};

const handleUpdateComponent = async (imageRelatedObject, currentImgUrl) => {
  if (!imageRelatedObject || !currentImgUrl) {
    console.error('Invalid parameters for updateComponent:', {
      imageRelatedObject,
      currentImgUrl,
    });
    return;
  }

  console.log(
    `Updating component: Object ID: ${imageRelatedObject}, Image URL: ${currentImgUrl}`
  );

  try {
    await updateComponent(
      imageRelatedObject,
      null, // other parameters as needed
      null,
      null,
      null,
      null,
      currentImgUrl,
      null
    );
    console.log('Component updated successfully');
  } catch (error) {
    console.error('Error updating component:', error);
  }
};

const handleUpdateAnchor = async (imageRelatedObject, currentImgUrl) => {
  if (!imageRelatedObject || !currentImgUrl) {
    console.error('Invalid parameters for updateAnchor:', {
      imageRelatedObject,
      currentImgUrl,
    });
    return;
  }

  console.log(
    `Updating anchor: Object ID: ${imageRelatedObject}, Image URL: ${currentImgUrl}`
  );

  try {
    await updateAnchor(
      imageRelatedObject,
      null, // other parameters as needed
      null,
      null,
      null,
      null,
      currentImgUrl
    );
    console.log('Anchor updated successfully');
  } catch (error) {
    console.error('Error updating anchor:', error);
  }
};


  const handleCancel = () => {
    setUploadedImages([]);
  };

  useEffect(() => {
      const currentOrgId = localStorage.getItem("fxLocalOrgID");
      fxAPIFetchProjectsByOrgId(currentOrgId);
      fxAPIFetchComponentsByOrgId(currentOrgId);
      if(props.relatedProject) {
        fxAPIFetchAnchorsObjectivesByProjectId(props.relatedProject);
      }
    }, []);

  useEffect(() => {
    console.log('Uploaded image set changed to: ');
    console.dir(uploadedImages);
  }, [uploadedImages]);

  return (
    <Box sx={{ p: 2 }}>
      {uploadedImages.length === 0 ? (
        <Box
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          sx={{
            border: '2px dashed #ccc',
            borderRadius: '8px',
            textAlign: 'center',
            padding: '40px',
            cursor: 'pointer',
          }}
        >
          <Typography variant="h6">Drag and Drop Images Here</Typography>
          <Typography variant="body2">
            Or click to browse your files.
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Uploaded Images</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
          <List>
            {uploadedImages.map((img) => (
              <>
              <ListItem
                key={img.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid #e0e0e0',
                  py: 1,
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    src={img.id}
                    alt="Thumbnail"
                    sx={{ width: 56, height: 56 }}
                  />
                </ListItemAvatar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      label="Title"
                      value={img.title}
                      onChange={(e) =>
                        handleTitleChange(img.id, e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {props.context === "default" &&
                    <Select
                      value={img.project}
                      onChange={(e) =>
                        handleProjectChange(img.id, e.target.value)
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select Project</em>
                      </MenuItem>
                      {projectsByOrgId.map((project) => (
                        <MenuItem key={project.project_id} value={project.project_id}>{project.name}</MenuItem>
                      ))}
                    </Select>
                    }
                    {props.context === "component" &&
                    <Select
                      value={img.component}
                      onChange={(e) =>
                        handleComponentChange(img.id, e.target.value)
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select Component</em>
                      </MenuItem>
                      {componentsByOrgId.map((component) => (
                        <MenuItem key={component.component_id} value={component.component_id}>{component.name}</MenuItem>
                      ))}
                    </Select>
                    }
                    {props.context === "anchor" &&
                    <Select
                      value={img.anchor}
                      onChange={(e) =>
                        handleAnchorChange(img.id, e.target.value)
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select Anchor</em>
                      </MenuItem>
                      {anchorsObjectivesByProjectId.map((anchor) => (
                        <MenuItem key={anchor.anchor_id} value={anchor.anchor_id}>{anchor.anchor_name}</MenuItem>
                      ))}
                    </Select>
                    }
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddImage(img.id)}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                
              </ListItem>
              </>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

export default CompUIAssetBulkProcessor;
