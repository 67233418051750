import axios from 'axios';

const createComponent = async (name, description, version, related_org, config_cat, related_img, related_category) => {
    try {
      // Generate projectId
      const componentId = generateUUID();
  
      // Simple validation
      if (!componentId || !name || !related_org) {
        console.warn('Missing required parameters: componentId, name, or related_org.');
        return;
      }
  
      console.log(`Creating component for orgId: ${related_org}, name: ${name}, description: ${description}, version: ${version}, config category: ${config_cat}, related img: ${related_img}, related category: ${related_category}`);
  
      // Send request
      const response = await axios.post('/fxapi/components/create', {
        component_id: componentId,
        name,
        description,
        version,
        related_org,
        config_cat,
        related_img,
        related_category
      });
  
      console.log('API response received:', response.data);
  
    } catch (error) {
      // Suppress the error, just log it quietly
      console.error('Error creating component:', error);
      // Optionally, handle specific errors here without alerting the user
    }
  };

  const getComponentsByOrgId = async (orgId, setError, setComponentsByOrgData) => {
    setError(null);
    setComponentsByOrgData([]); // Reset Components data to an empty array

    if (!orgId) {
      setError('orgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching component data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/components/components-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting component data:', items);
      setComponentsByOrgData(items); // Correctly update the state with received data
      console.log('Updated component data:', items);
    } catch (error) {
      console.error('Error fetching component data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch components data');
    }
};

const getComponentsByOrgAndCategory = async (orgId, categoryId, setError, setComponentsByCategoryData) => {
  setError(null);
  setComponentsByCategoryData([]); // Reset Components data to an empty array

  if (!categoryId) {
    setError('categoryId parameter is required');
    return;
  }

  try {
    console.log(`Fetching component data for categoryId: ${categoryId} and orgId: ${orgId}`);
    const response = await axios.get('/fxapi/components/components-by-category', {
      params: { orgId, categoryId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting component data:', items);
    setComponentsByCategoryData(items); // Correctly update the state with received data
    console.log('Updated component data:', items);
  } catch (error) {
    console.error('Error fetching component data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch components data');
  }
};

const deleteComponent = async (componentId, setError, setDeleteResponse) => {
    setError(null);
    setDeleteResponse(null); // Reset delete response
  
    if (!componentId) {
      setError('componentId parameter is required');
      return;
    }
  
    try {
      console.log(`Deleting component with ID: ${componentId}`);
      const response = await axios.delete(`/fxapi/components/delete/${componentId}`);
      console.log('API response received:', response.data);
  
      // Convert response data to plain text
      const successMessage = `Successfully deleted component with ID: ${componentId}`;
      setDeleteResponse(successMessage); // Update the state with plain text
      console.log('Updated delete response:', successMessage);
    } catch (error) {
      console.error('Error deleting component:', error);
  
      // Convert error to plain text
      const errorMessage = error.response ? error.response.data : 'Failed to delete component';
      setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
    }
  };

  const updateComponent = async (componentId, name, description, version, related_org, config_cat, related_img, related_category) => {

    try {
      const response = await axios.put(`/fxapi/components/update/${componentId}`, {
        name: name,
        description: description,
        version: version,
        related_org: related_org,
        config_cat: config_cat,
        related_img: related_img,
        related_category: related_category
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  

  // Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  
  export { createComponent, getComponentsByOrgId, getComponentsByOrgAndCategory, deleteComponent, updateComponent};