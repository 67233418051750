import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CompStartAuthenticated.css';
import Button from '@mui/material/Button';
import CompPageTempIntroSlide from './CompPageTempIntroSlide';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

const CompStartAuthenticated = (props) => {

  const storedRole = localStorage.getItem("fxLocalUserRole");

  const [userRole, setUserRole] = React.useState(storedRole);

  const handleUserRoleChange = (event) => {
    setUserRole(event.target.value);
  };

  const navigate = useNavigate();

    const handleClickRole = (userRole) => {
        // Process some logic here
        console.log('Processing logic...');
        localStorage.setItem("fxLocalUserRole", userRole);

        // After processing logic, navigate to the desired route
        navigate('/dashboard/role/' + userRole);
    };

  const [gettingStartedCurrentStep, setGettingStartedCurrentStep] = useState('create-organization');

  const entryStepOrganizationLeftPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
        <div className="fx-comp-start-setup-step-left-panel-body">Welcome to Fluxity
        <br /><br />
        {/*Choose which role you'd like to view Fluxity as.*/}
        Start designing differently!
        </div>
      </div>
    </>
  );

  const entryStepOrganizationRightPanel = (
    <>
      <div className="fx-comp-startsetup-step-container-inner">
                {/*
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                  
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Choose a Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userRole}
                        label="User Role"
                        onChange={handleUserRoleChange}
                      >
                        <MenuItem value="c-level">Executive</MenuItem>
                        <MenuItem value="scrum-master">Scrum Master</MenuItem>
                        <MenuItem value="business-analyst">Business Analyst</MenuItem>
                        <MenuItem value="developer">Developer</MenuItem>
                        <MenuItem value="ux-designer">UX Designer</MenuItem>
                      </Select>
                    </FormControl>
                    
                    
                  </Grid>
                  <Grid item xs={3}>
                      <Button variant="contained" onClick={() => handleClickRole(userRole)}>Start</Button>
                  </Grid>
                </Grid>
                */}
                <div className="fx-page-start-start-button-wrap">
                  <Button variant="contained" onClick={() => handleClickRole(userRole)}>Start</Button>
                </div>
                
      </div>
    </>
  );

  return (
    <div>
        <CompPageTempIntroSlide headingOne="Let's Get Started." panelLeftContent={entryStepOrganizationLeftPanel} panelRightContent={entryStepOrganizationRightPanel} />  
    </div>
  );
};

export default CompStartAuthenticated;