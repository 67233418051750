import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CompActivityHistory from './CompActivityHistory';

import './CompBottomTray.css';


const CompBottomTray = (props) => {

  // Hide some aspects for production users temporarily
  const localEnv = localStorage.getItem('fxEnv');
  console.log('local environment on header: ' + localEnv);
  

  return (
    <div className='fx-bottom-tray-container'>
      <div className='fx-bottom-tray-left-items'>
        <CompActivityHistory />
      </div>
      {localEnv !== 'production' &&
        <div className='fx-bottom-tray-right-items'>
          AI
        </div>
      }
    </div>
  );
};

export default CompBottomTray;
