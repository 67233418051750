import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageDesignSessions.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import Button from '@mui/material/Button';
import CompContentBlock from '../Components/CompContentBlock';
import axios from 'axios';
import CompDesignSessionsDashboard from '../Components/CompDesignSessionsDashboard';


const PageDesignSessions = (props) => {

  const [error, setError] = useState(null);

  

  return (
    <CompPageTempMaster3Panel headingOne="Design Sessions">

      <CompDesignSessionsDashboard />
      
    </CompPageTempMaster3Panel>
  );
}

export default PageDesignSessions;