import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import './CompModalAnchorLinkDevices.css';
import { updateAnchor } from '../fxAPI/fxAnchorsAPI.js';
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI.js';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const CompModalAnchorLinkDevices = (props) => {

  const [error, setError] = useState(null);

  const storedLocalOrgConfig = localStorage.getItem('fxLocalOrgConfig');
  const parsedOrgConfig = storedLocalOrgConfig ? JSON.parse(storedLocalOrgConfig) : null;
  console.log('parsed devices: ');
  console.dir(parsedOrgConfig.devices);

const [open, setOpen] = React.useState(false);

const orgDevices = parsedOrgConfig.devices;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateLink = async (linkAnchorId, linkDeviceId) => {
      //event.preventDefault();
      const related_org = localStorage.getItem("fxLocalOrgID");
      const initial_object_id = linkAnchorId;
      const initial_object_type = 'anchor';
      const linking_object_id = linkDeviceId;
      const linking_object_type = 'device';
      const relationship_type = '';
      const related_project = props.relatedProjectId;
  
      await createObjectLink(initial_object_id, initial_object_type, linking_object_id, linking_object_type, relationship_type, related_project, related_org);
    };

  return (
    <React.Fragment>
      <div onClick={handleClickOpen}>Associate Devices</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Associate Devices with this Step
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className='fx-comp-anchor-device-assign-items-container'>
          {orgDevices.map((deviceItem) => (
            <div key={deviceItem.id} className='fx-comp-anchor-device-assign-item'>
                <div className='fx-comp-anchor-device-assign-item-icon-portion'>
                    icon...
                </div>
                <div className='fx-comp-anchor-device-assign-item-title-portion'>
                    <div><strong>{deviceItem.deviceName}</strong></div>
                    {/*<div><Button variant='outlined' onClick={() => handleUpdateAnchor(props.relatedAnchorId, uiAssetItem.ui_asset_id)}>Assign as Primary UI</Button></div>*/}
                    <Button variant='outlined' onClick={() => handleCreateLink(props.relatedAnchorId, deviceItem.id)}>Associate Device</Button>
                </div>
            </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default CompModalAnchorLinkDevices;