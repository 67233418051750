import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import './CompActivityHistory.css';
import { getSocket } from '../Utilities/socket';
import { getActivityHistoryByOrgId, createActivityHistory } from '../fxAPI/fxActivityHistoryAPI';
import Drawer from '@mui/material/Drawer';
import CompMiniAvatar from './CompMiniAvatar';

const CompActivityHistory = () => {
  const socket = getSocket();

  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activityHistoryByOrg, setActivityHistoryByOrgData] = useState([]);
  const [recentlyAddedId, setRecentlyAddedId] = useState(null);

  // Fetch activity history by organization ID
  const fxAPIFetchActivityHistoryByOrgId = async (orgId) => {
    const data = await getActivityHistoryByOrgId(orgId, setError);
    const sortedData = data.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
    setActivityHistoryByOrgData(sortedData);
    localStorage.setItem('activityHistory', JSON.stringify(sortedData)); // Save to local storage
  };

  // Load activity history from local storage on component mount
  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    const savedHistory = localStorage.getItem('activityHistory');

    if (savedHistory) {
      setActivityHistoryByOrgData(JSON.parse(savedHistory));
    }

    if (currentOrgId) {
      fxAPIFetchActivityHistoryByOrgId(currentOrgId);
    }
  }, []);

  // Listen for real-time activity history updates via socket
  useEffect(() => {
    if (!socket) return;

    socket.on('activity-history-created', (newActivityHistory) => {
      setActivityHistoryByOrgData((prevHistory) => {
        const updatedHistory = [newActivityHistory, ...prevHistory];
        const sortedHistory = updatedHistory.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
        localStorage.setItem('activityHistory', JSON.stringify(sortedHistory)); // Save updated history
        return sortedHistory;
      });
      setRecentlyAddedId(newActivityHistory.activity_history_id); // Track the new item
    });

    return () => {
      socket.off('activity-history-created');
    };
  }, [socket]);

  // Handle creating a new activity history
  const handleCreateActivityHistory = async (event) => {
    event.preventDefault();

    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const message = "Test message from activity feed";
    const related_object_type = "objective";
    const related_object = "cf575140-9601-45b3-82bc-92c30dbfcb83";
    const category = null;
    const tags = null;
    const metadata = null;
    const related_project = null;
    const authoring_user = localStorage.getItem("fxLocalUserID");
    const source = "fluxity";

    const newActivity = await createActivityHistory(
      message,
      related_object_type,
      related_object,
      category,
      tags,
      metadata,
      relatedOrg,
      related_project,
      authoring_user,
      source,
      socket,
      true
    );

    socket.emit('activity-history-created', newActivity.data);
  };

  // Drawer toggle
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>Activity Feed</Button>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '350px',
            padding: '16px',
          },
        }}
      >
        <div role="presentation" onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
          <h2>Activity Feed</h2>
          <Button onClick={handleCreateActivityHistory}>Create Activity History</Button>
          {activityHistoryByOrg.map((activityHistory) => (
            <div
              key={activityHistory.activity_history_id}
              className={`fx-activity-history-item ${
                recentlyAddedId === activityHistory.activity_history_id ? 'new-item' : ''
              }`}
            >
              <div className='fx-comp-activity-history-item-avatar'>
                <CompMiniAvatar user={activityHistory.authoring_user} />
              </div>
              <div className='fx-comp-activity-history-item-message-stack'>
                <div className='fx-comp-activity-history-item-message-stack-source'>
                {activityHistory.source ? (
                  <>
                    {activityHistory.source}
                  </>
                ) : (
                  <>
                    Fluxity
                  </>
                )}
                </div>
                <div className='fx-comp-activity-history-item-message-stack-message'>{activityHistory.message}</div>
                <div className='fx-comp-activity-history-item-message-stack-date'>{activityHistory.date_time}</div>
              </div>
            </div>
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default CompActivityHistory;
