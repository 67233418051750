import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import './CompHeader.css';
import FluxityLogo from '../images/Fluxity_Logo.svg';
import { useAuth0 } from "@auth0/auth0-react";


export default function CompHeader() {

  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();

  // Hide some aspects for production users temporarily
  const localEnv = localStorage.getItem('fxEnv');
  console.log('local environment on header: ' + localEnv);

  const storedLocalUserName = localStorage.getItem("fxLocalUserName");
  const userRole = localStorage.getItem("fxLocalUserRole");
  const userRoleURL = '/dashboard/role/' + userRole;

  const storedLocalOrgId = localStorage.getItem("fxLocalOrgID");
      console.log('Local Org ID: ' + storedLocalOrgId);

  return (
    <div className="fx-comp-header-outer">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className="fx-comp-header-appbar" position="static">
          <Toolbar>
            <div>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                className="fx-hide-menu"
              >
                <MenuIcon />
              </IconButton>
              <a href={userRoleURL}><img width="auto" height="70px" alt="logo" src={FluxityLogo} /></a>
            </div>
            
            <div>
            {storedLocalOrgId &&
              <div >
                <span className="fx-comp-header-hidden">
                  <div className='fx-comp-header-metric-item'>
                    <div className='fx-comp-header-metric-primetext'>03</div>
                    <div className='fx-comp-header-metric-subtext'>Total Playbooks</div>
                  </div>
                  <div className='fx-comp-header-metric-item'>
                    <div className='fx-comp-header-metric-primetext'>03</div>
                    <div className='fx-comp-header-metric-subtext'>Total Objectives</div>
                  </div>
                  <div className='fx-comp-header-metric-item'>
                    <div className='fx-comp-header-metric-primetext'>03</div>
                    <div className='fx-comp-header-metric-subtext'>UX Health</div>
                  </div>
                  <div className='fx-comp-header-metric-item'>
                    <div className='fx-comp-header-metric-primetext'>03</div>
                    <div className='fx-comp-header-metric-subtext'>Accessibility Health</div>
                  </div>
                  <div className='fx-comp-header-metric-item'>
                    <div className='fx-comp-header-metric-primetext'>03</div>
                    <div className='fx-comp-header-metric-subtext'>UI Health</div>
                  </div>
                  <div className='fx-comp-header-metric-item'>
                    <div className='fx-comp-header-metric-primetext'>590K</div>
                    <div className='fx-comp-header-metric-subtext'>Total Budget</div>
                  </div>
                </span>
                {userRole === 'end-user' &&
                <>
                  <a href="/end-user-tools" className='fx-comp-header-link'><Button color="inherit">User Tools</Button></a>
                </>
                }

                {userRole !== 'end-user' &&
                <>
                  <a href={userRoleURL} className='fx-comp-header-link'><Button color="inherit">My Home</Button></a>
                  <a href="/profile-user" className='fx-comp-header-link'><Button color="inherit">My Profile</Button></a>
                  <a href="/team" className='fx-comp-header-link'><Button color="inherit">My Team</Button></a>
                  {localEnv !== 'production' &&
                    <a href="/fluxity-canvas" className='fx-comp-header-link'><Button color="inherit">Canvas</Button></a>
                  }
                  </>
                }
              </div>
              }
              {isAuthenticated &&
                <>
                <div className='fx-comp-header-profile-name'>{storedLocalUserName}</div>
                <Button 
                  onClick={() => {
                    // Clear local storage variables
                    localStorage.removeItem('fxLocalUserName');
                    localStorage.removeItem('fxLocalUserRole');
                    localStorage.removeItem('fxLocalOrgID');

                    // Trigger logout
                    logout({ logoutParams: { returnTo: window.location.origin } });
                  }} 
                  variant="outlined" 
                  className="fx-comp-header-profile"
                >
                  Log out
                </Button>
                </>
              }
              <div>
                {/*[status search here...]*/}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Box>  
    </div>
  );
}