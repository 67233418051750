import React, { useState, useEffect } from 'react';
import './CompUIAssetBulkProcessor.css';
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  TextField,
  Select,
  MenuItem,
  Button,
  Collapse,
} from '@mui/material';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { createUIAsset } from '../fxAPI/fxUIAssetsAPI.js';
import { getComponentsByOrgId, updateComponent } from '../fxAPI/fxComponentsAPI.js';
import CompCollapsibleContainer from './CompCollapsibleContainer.jsx';

const CompUIAssetBulkProcessor = (props) => {

  const [error, setError] = useState(null);

  const [imageUrl, setImageUrl] = useState('');
  const [uiAssetResponse, setUIAssetResponse] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

/* PROJECTS */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  /* COMPONENTS */
  const [componentsByOrgId, setComponentsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchComponentsByOrgId = async (orgId) => {
    await getComponentsByOrgId(orgId, setError, setComponentsByOrgData);
    console.log('all components by org Id');
    console.dir(componentsByOrgId);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith('image/')
    );
    const images = files.map((file) => ({
      id: URL.createObjectURL(file),
      file,
      title: '',
      project: '',
    }));
    setUploadedImages(images);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTitleChange = (id, newTitle) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, title: newTitle } : img
      )
    );
  };

  const handleProjectChange = (id, newProject) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, project: newProject } : img
      )
    );
  };

  const handleComponentChange = (id, newComponent) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, component: newComponent } : img
      )
    );
  };

  const handleAddImage = async (id) => {
    // Find the image by ID
    const image = uploadedImages.find((img) => img.id === id);
    if (!image || !image.file) {
        console.error('No valid image file found for the provided ID');
        return;
    }

    console.log('Add Image:', image);
    console.log('Image data:');
    console.dir(image.file);

    // Prepare the FormData for upload
    const formData = new FormData();
    formData.append('file', image.file);

    try {
        // Upload the image to the CDN
        const response = await fetch('https://fluxity.io/fxapi/upload-image', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Failed to upload image: ${response.statusText}`);
        }

        // Parse the response
        const data = await response.json();
        console.log('Server Response:', data);

        // Extract the CDN URL and handle it
        if (data && data.result && data.result.variants) {
            const cdnUrl = data.result.variants[0];
            setImageUrl(cdnUrl);

            // Call handleCreateUIAsset after the image URL is set
            handleCreateUIAsset(cdnUrl, image.title, image.project);
        } else {
            throw new Error('Invalid response structure or upload failed');
        }
    } catch (error) {
        console.error('Error uploading image:', error);
    }
};

const handleCreateUIAsset = (currentImgUrl, imageTitle, imageRelatedObject) => {
  console.log('current image url: ' + currentImgUrl);
  const imgFull = currentImgUrl; // from saved full
  const imgThumbPrefix = currentImgUrl.replace("/public", "");
  const imgThumb = imgThumbPrefix + "/w=200,h=200,fit=crop,crop=centered";
  const version = 'version here';
  const relatedProject = imageRelatedObject;
  const name = imageTitle;
  /*
  if(props.context === "default") {
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    // Assuming createUIAsset is an imported function
    createUIAsset(relatedOrg, relatedProject, name, imgFull, imgThumb, version, setUIAssetResponse);
  } else if(props.context === "component") {
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    // Assuming createUIAsset is an imported function
    createUIAsset(relatedOrg, null, name, imgFull, imgThumb, version, setUIAssetResponse);
    handleUpdateComponent(imageRelatedObject, name, relatedOrg, currentImgUrl);
  }
    */
  const relatedOrg = localStorage.getItem("fxLocalOrgID");
  handleUpdateComponent(imageRelatedObject, name, relatedOrg, currentImgUrl);
  
};

  const handleUpdateComponent = async () => {
    //console.log('called new update handle component for object: ' + imageRelatedObject + ' name: ' + name + ' related Org: ' + relatedOrg + ' current Image URL: ' + currentImgUrl);
    await updateComponent('a80fb8c3-8da1-48ab-8c2e-6df798994eac', 'test', null, null, '11111', null, 'test', null);
  }

  const handleCancel = () => {
    setUploadedImages([]);
  };

  useEffect(() => {
      const currentOrgId = localStorage.getItem("fxLocalOrgID");
      fxAPIFetchProjectsByOrgId(currentOrgId);
      fxAPIFetchComponentsByOrgId(currentOrgId);
    }, []);

  useEffect(() => {
    console.log('Uploaded image set changed to: ');
    console.dir(uploadedImages);
  }, [uploadedImages]);

  return (
    <Box sx={{ p: 2 }}>
      {uploadedImages.length === 0 ? (
        <Box
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          sx={{
            border: '2px dashed #ccc',
            borderRadius: '8px',
            textAlign: 'center',
            padding: '40px',
            cursor: 'pointer',
          }}
        >
          <Typography variant="h6">Drag and Drop Images Here</Typography>
          <Typography variant="body2">
            Or click to browse your files.
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Uploaded Images</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
          <List>
            {uploadedImages.map((img) => (
              <>
              <ListItem
                key={img.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid #e0e0e0',
                  py: 1,
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    src={img.id}
                    alt="Thumbnail"
                    sx={{ width: 56, height: 56 }}
                  />
                </ListItemAvatar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      label="Title"
                      value={img.title}
                      onChange={(e) =>
                        handleTitleChange(img.id, e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {props.context === "default" &&
                    <Select
                      value={img.project}
                      onChange={(e) =>
                        handleProjectChange(img.id, e.target.value)
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select Project</em>
                      </MenuItem>
                      {projectsByOrgId.map((project) => (
                        <MenuItem key={project.project_id} value={project.project_id}>{project.name}</MenuItem>
                      ))}
                    </Select>
                    }
                    {props.context === "component" &&
                    <Select
                      value={img.component}
                      onChange={(e) =>
                        handleComponentChange(img.id, e.target.value)
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select Component</em>
                      </MenuItem>
                      {componentsByOrgId.map((component) => (
                        <MenuItem key={component.component_id} value={component.component_id}>{component.name}</MenuItem>
                      ))}
                    </Select>
                    }
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      //onClick={() => handleAddImage(img.id)}
                      onClick={() => handleUpdateComponent()}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                
              </ListItem>
              </>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

export default CompUIAssetBulkProcessor;
