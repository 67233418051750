import React from 'react';
import './CompDesignSessionsDashboard.css';
import CompDashCardGallery from './CompDashCardGallery';


const CompDesignSessionsDashboard = (props) => {

  return (
    <div>
      <CompDashCardGallery galleryType="design-session" />
    </div>
  );
};

export default CompDesignSessionsDashboard;