import React, { useState, useContext } from 'react';
import './CompQuickComplete.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { updateObjective } from '../fxAPI/fxObjectivesAPI';
import { updateSubjective } from '../fxAPI/fxSubjectivesAPI';
import { PanelContext } from '../Contexts/PanelContext';

const CompQuickComplete = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { triggerElementRefresh } = useContext(PanelContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log('called popover, current status / progress is: ' + props.currentProgressStatus);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleComplete = () => {
    console.log('marked complete');
    setSnackbarOpen(true); // Show Snackbar
    if(props.objectType === "objective") {
        const objectiveId = props.relatedObjectId;
        updateObjective(objectiveId, null, null, null, null, null, null, "100", null);
    }
    if(props.objectType === "subjective") {
        const subjectiveId = props.relatedObjectId;
        updateSubjective(subjectiveId, null, null, "complete", null, null, null, null, null, null, null);
    }
    triggerElementRefresh('subjectivePanel');
    handleClose(); // Close Popover
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return; // Prevent Snackbar from closing on clickaway
    }
    setSnackbarOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="fx-quick-complete-popover-container"
        PaperProps={{
          style: {
            padding: '16px',
            minWidth: '200px',
          },
        }}
      >
        <div className="fx-quick-complete-popover-row" onClick={handleComplete}>
          Mark Complete
        </div>
      </Popover>
      
        {
        props.currentProgressStatus === "complete" || props.currentProgressStatus === "100"
            ? <CheckCircleOutlineIcon color="success" />
            : <div className="fx-quick-complete-popover-icon"><CheckCircleOutlineIcon aria-describedby={id} onClick={handleClick} /></div> // Replace with the component or JSX to render for the "else" condition
        }
      
      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Snackbar duration (3 seconds)
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            {props.objectType === "objective" && 
                <div>Objective Complete!</div>
            }
            {props.objectType === "subjective" && 
                <div>Subjective Complete!</div>
            }
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CompQuickComplete;
