import React, { useState, useEffect } from "react";
import { getMilestonesByProjectId } from "../fxAPI/fxMilestonesAPI";
import "./CompTimelineMinimal.css";
import Button from "@mui/material/Button";
import CompCRUDModal from "./CompCRUDModal.jsx";
import CompCollapsibleContainer from "./CompCollapsibleContainer.jsx";

const CompTimelineMinimal = ({ projectId }) => {
  const [error, setError] = useState(null);
  const [milestonesByProjectId, setMilestonesByProjectData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  const [modalComponentData, setModalComponentData] = useState([]);
  const [currentObjectId, setCurrentObjectId] = useState(null);

  const openModal = (itemType, currentCRUDMethod, currentModalComponentData) => {
    setItemType(itemType);
    setCRUDMethod(currentCRUDMethod);
    setModalComponentData(currentModalComponentData);
    if (currentCRUDMethod !== "create") {
      setCurrentObjectId(currentModalComponentData.milestone_id);
    }
    setActionModalTitle(
      itemType === "project" ? "Create Playbook" : "Create Playbook"
    );
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  // Fetch milestones from the API
  const fxAPIFetchMilestonesByProjectId = async (projectId) => {
    await getMilestonesByProjectId(projectId, setError, setMilestonesByProjectData);
  };

  useEffect(() => {
    fxAPIFetchMilestonesByProjectId(projectId);
  }, [projectId]);

  const calculateWidth = (startDate, endDate) => {
    const totalMonths = 12;
    const now = new Date();
    const nextYear = new Date(now);
    nextYear.setMonth(nextYear.getMonth() + totalMonths);

    const totalDuration = nextYear - now;
    const milestoneDuration = new Date(endDate) - new Date(startDate);
    const width = (milestoneDuration / totalDuration) * 100;

    return `${Math.max(0, Math.min(width, 100))}%`;
  };

  const calculateLeft = (startDate) => {
    const now = new Date();
    const totalMonths = 12;
    const nextYear = new Date(now);
    nextYear.setMonth(nextYear.getMonth() + totalMonths);

    const totalDuration = nextYear - now;
    const offset = new Date(startDate) - now;
    const left = (offset / totalDuration) * 100;

    return `${Math.max(0, Math.min(left, 100))}%`;
  };

  const getStaggeredRows = (milestones) => {
    const rows = [];
    milestones.forEach((milestone) => {
      const startDate = new Date(milestone.date_start);
      const endDate = new Date(milestone.date_end);

      let placed = false;
      for (const row of rows) {
        if (
          !row.some(
            (m) =>
              new Date(m.date_start) < endDate && new Date(m.date_end) > startDate
          )
        ) {
          row.push(milestone);
          placed = true;
          break;
        }
      }

      if (!placed) {
        rows.push([milestone]);
      }
    });

    return rows;
  };

  const sortedMilestones = [...milestonesByProjectId].sort(
    (a, b) => new Date(a.date_start) - new Date(b.date_start)
  );
  const staggeredRows = getStaggeredRows(sortedMilestones);

  return (
    <>
      <CompCRUDModal
        isOpen={isOpen}
        onClose={closeModal}
        modalTitle={actionModalTitle}
        itemType="milestone"
        crudMethod={crudMethod}
        modalComponentData={modalComponentData}
        relatedProject={projectId}
        relatedComponentId={currentObjectId}
      />
      <CompCollapsibleContainer containerHeading="Playbook Timeline">
      <div className="fx-comp-timeline-minimal-toolbar-thin">
        <Button variant="outlined" onClick={() => openModal("milestone", "create", null)}>Create Milestone</Button>
      </div>
      <div className="fx-comp-timeline-minimal-container">
        {staggeredRows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="fx-comp-timeline-minimal-row"
            style={{
              height: "10px",
              width: "100%",
              position: "relative",
              marginBottom: "5px",
            }}
          >
            {row.map((milestone) => (
              <div
                key={milestone.milestone_id}
                className="fx-comp-timeline-minimal-milestone"
                style={{
                  position: "absolute",
                  left: calculateLeft(milestone.date_start),
                  width: calculateWidth(
                    milestone.date_start,
                    milestone.date_end
                  ),
                  height: "100%",
                  backgroundColor: "#007bff",
                  borderRadius: "4px",
                }}
                title={`${milestone.name}: ${milestone.date_start} - ${milestone.date_end}`}
                onClick={() => openModal("milestone", "view-edit", milestone)}
              />
            ))}
          </div>
        ))}
      </div>
      </CompCollapsibleContainer>
    </>
  );
};

export default CompTimelineMinimal;
