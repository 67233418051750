import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import './PagePlaybookObjectiveMap.css';
import CompTeamMemberList from '../Components/CompTeamMemberList';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompObjectiveAnchors from '../Components/CompObjectiveAnchors';

const PagePlaybookObjectiveMap = (props) => {
  
    const params = useParams();

  return (
    <CompPageTempMaster3Panel headingOne="Objective Map">

        <CompObjectiveAnchors relatedProject={params.projectId} /> 
   
    </CompPageTempMaster3Panel>
  );
};

export default PagePlaybookObjectiveMap;