import logo from './logo.svg';
import { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CompHeader from './Components/CompHeader'
import PageHome from './Pages/PageHome';
import PageOnboardingWizard from './Pages/PageOnboardingWizard';
import PageAuthCallback from './Pages/PageAuthCallback';
import PageLoginAlt from './Pages/PageLoginAlt';
import PageStart from './Pages/PageStart';
import PageDashboard from './Pages/PageDashboard';
import PageReportsUXAudit from './Pages/PageReportsUXAudit';
import PageProfileUser from './Pages/PageProfileUser';
import PageUIAssetDetail from './Pages/PageUIAssetDetail';
import PageIntegrations from './Pages/PageIntegrations';
import PageComponents from './Pages/PageComponents';
import PageObjectLinks from './Pages/PageObjectLinks';
import PageIntegrationTest from './Pages/PageIntegrationTest';
import AuthMSTeamsCallback from './Components/AuthMSTeamsCallback';
import PageSubjectiveTemplates from './Pages/PageSubjectiveTemplates';
import PageTeam from './Pages/PageTeam';
import PageFluxityCanvas from './Pages/PageFluxityCanvas';
import PageBusinessValue from './Pages/PageBusinessValue';
import PageEndUserTools from './Pages/PageEndUserTools';
import PageDesignSessions from './Pages/PageDesignSessions';
import PageUIAssets from './Pages/PageUIAssets';
import { initializeSocket, disconnectSocket } from './Utilities/socket';
import PageBusinessValueDetail from './Pages/PageBusinessValueDetail';
import PageDesignSessionDetail from './Pages/PageDesignSessionDetail';
import PageAccessibility from './Pages/PageAccessibility';
import PagePlaybookObjectiveMap from './Pages/PagePlaybookObjectiveMap';
import PagePlaybookObjectiveStack from './Pages/PagePlaybookObjectiveStack';
import PagePlaybookJourneyMap from './Pages/PagePlaybookJourneyMap';

function App() {

  useEffect(() => {
    // Initialize the socket connection when the app starts
    initializeSocket();

    // Cleanup socket connection when app unmounts
    return () => {
      // Optionally, you can disconnect the socket when the app unmounts
      disconnectSocket();
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/" element={<Start/>}/>
          <Route exact path="/callback" element={<AuthCallback/>}/>
          <Route exact path="/onboarding-wizard" element={<OnboardingWizard/>}/>
          <Route exact path="/login-alt" element={<LoginAlt/>}/>
          <Route exact path="/dashboard/:type/:compId" element={<Dashboard/>}/>
          <Route exact path="/reports/ux-audit" element={<ReportsUXAudit/>}/>
          <Route exact path="/profile-user" element={<ProfileUser/>}/>
          <Route exact path="/ui-asset-detail/:compId" element={<UIAssetDetail/>}/>
          <Route exact path="/integrations/" element={<Integrations/>}/>
          <Route exact path="/components/" element={<Components/>}/>
          <Route exact path="/object-links/" element={<ObjectLinks/>}/>
          <Route exact path="/integration-test/" element={<IntegrationTest/>}/>
          <Route exact path="/auth/callback" element={<AuthMSTeamsCallback />}/>
          <Route exact path="/subjective-templates" element={<SubjectiveTemplates />}/>  
          <Route exact path="/team/" element={<Team />}/>
          <Route exact path="/fluxity-canvas/" element={<FluxityCanvas />}/>
          <Route exact path="/business-value/" element={<BusinessValue />}/>
          <Route exact path="/end-user-tools/" element={<EndUserTools />}/>
          <Route exact path="/design-sessions/" element={<DesignSessions />}/>
          <Route exact path="/design-session-detail/:designSessionId" element={<DesignSessionDetail />} />
          <Route exact path="/ui-assets/" element={<UIAssets />}/>
          <Route exact path="/business-value-detail/:businessValueId" element={<BusinessValueDetail />}/>
          <Route exact path="/accessibility/" element={<Accessibility />}/>
          <Route exact path="/playbook/objective-map/:projectId" element={<PlaybookObjectiveMap />}/> 
          <Route exact path="/playbook/objective-stack/:projectId" element={<PlaybookObjectiveStack />}/> 
          <Route exact path="/playbook/journey-map/:projectId" element={<PlaybookJourneyMap />}/>     
        </Routes>
      </Router>
    </div>
    
  );
}

function Home() {
  return (
    <PageHome />
  );
}

function Start() {
  return (
    <PageStart />
  );
}

function OnboardingWizard() {
  return (
    <PageOnboardingWizard />
  );
}

function AuthCallback() {
  return (
    <PageAuthCallback />
  );
}

function LoginAlt() {
  return (
    <PageLoginAlt />
  );
}

function Dashboard() {
  return (
    <PageDashboard />
  );
}

function ReportsUXAudit() {
  return (
    <PageReportsUXAudit />
  );
}

function ProfileUser() {
  return (
    <PageProfileUser />
  );
}

function UIAssetDetail() {
  return (
    <PageUIAssetDetail />
  );
}

function Integrations() {
  return (
    <PageIntegrations />
  );
}

function Components() {
  return (
    <PageComponents />
  );
}

function ObjectLinks() {
  return (
    <PageObjectLinks />
  );
}

function IntegrationTest() {
  return (
    <PageIntegrationTest />
  );
}

function SubjectiveTemplates() {
  return (
    <PageSubjectiveTemplates />
  );
}

function Team() {
  return (
    <PageTeam />
  );
}

function FluxityCanvas() {
  return (
    <PageFluxityCanvas />
  );
}

function BusinessValue() {
  return (
    <PageBusinessValue />
  );
}

function EndUserTools() {
  return (
    <PageEndUserTools />
  );
}

function DesignSessions() {
  return (
    <PageDesignSessions />
  );
} 

function UIAssets() {
  return (
    <PageUIAssets />
  );
} 

function BusinessValueDetail() {
  return (
    <PageBusinessValueDetail />
  );
}

function DesignSessionDetail() {
  return (
    <PageDesignSessionDetail />
  )
}

function Accessibility() {
  return (
    <PageAccessibility />
  )
}

function PlaybookObjectiveMap() {
  return (
    <PagePlaybookObjectiveMap />
  )
}

function PlaybookObjectiveStack() {
  return (
    <PagePlaybookObjectiveStack />
  )
}

function PlaybookJourneyMap() {
  return (
    <PagePlaybookJourneyMap />
  )
}


export default App;
