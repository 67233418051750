import React, { useState } from 'react';
import './PageUIAssets.css';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompUIAssetsGallery from '../Components/CompUIAssetsGallery.jsx';
import CompUIAssetBulkProcessor from '../Components/CompUIAssetBulkProcessor.jsx';

const PageUIAssets = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <CompPageTempMaster3Panel headingOne="UI Assets">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="UI Assets Tabs">
          <Tab label="UI Asset Gallery" />
          <Tab label="UI Bulk Upload" />
        </Tabs>
      </Box>
      <Box sx={{ p: 2 }}>
        {selectedTab === 0 && <CompUIAssetsGallery />}
        {selectedTab === 1 && <CompUIAssetBulkProcessor context="default" />}
      </Box>
    </CompPageTempMaster3Panel>
  );
};

export default PageUIAssets;