import React, { useState, useEffect } from 'react';
import './CompObjectDetail.css';
import Button from '@mui/material/Button';
import { getBusinessValueById } from '../fxAPI/fxBusinessValueAPI';
import { getDesignSessionById } from '../fxAPI/fxDesignSessionsAPI';
import CompBusinessValueObjectiveTree from './CompBusinessValueObjectiveTree';
import CompDesignSessionWorkspace from './CompDesignSessionWorkspace';



const CompObjectDetail = (props) => {

    const [error, setError] = useState(null);
    const [businessValueName, setBusinessValueName] = useState(null);
    const [designSessionName, setDesignSessionName] = useState(null);

    /* BUSINESS VALUE BY ID */
  const [businessValueById, setBusinessValueByIdData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchBusinessValueById = async (businessValueId) => {
    await getBusinessValueById(businessValueId, setError, setBusinessValueByIdData);
    console.log('business value by business value Id');
    console.dir(businessValueById);
  };

   /* DESIGN SESION BY ID */
   const [designSessionById, setDesignSessionByIdData] = useState([]); // Initialize as an array
   /* get user personas by orgId */
   const fxAPIFetchDesignSessionById = async (designSessionId) => {
     await getDesignSessionById(designSessionId, setError, setDesignSessionByIdData);
     console.log('design session by design session Id');
     console.dir(designSessionById);
   };

  useEffect(() => {
      //const currentOrgId = localStorage.getItem("fxLocalOrgID");
      fxAPIFetchBusinessValueById(props.detailObjectId);
      console.log('business value by business value Id');
      console.dir(businessValueById);

      fxAPIFetchDesignSessionById(props.detailObjectId);
      console.log('design session by design session Id');
      console.dir(designSessionById);
      
    }, []);

    useEffect(() => {
        console.log('Updated business value by businessValueId:', businessValueById);
        if(businessValueById && businessValueById.length > 0) {
            setBusinessValueName(businessValueById[0].name);
          }
        console.log('Updated design session by designSessionId:', designSessionById);
        if(designSessionById && designSessionById.length > 0) {
            setDesignSessionName(designSessionById[0].name);
        }
      }, [businessValueById, designSessionById]);

  return (
    <div>
        <div className='fx-comp-object-detail-toolbar'>
        {props.detailObjectType === "business-value" &&
            <a href="/business-value"><Button variant='outlined'>Back to Business Value</Button></a>
        }
        {props.detailObjectType === "design-session" &&
            <>
            <a href="/design-sessions"><Button variant='outlined'>Back to Design Sessions</Button></a>
            <Button variant='outlined'>Add UI for Review</Button>
            <Button variant='outlined'>Add Participants</Button>
            </>
        }
        </div>
      
      {props.detailObjectType === "business-value" &&
      <>
        {/*<div>Test object1: {props.detailObjectId}</div>*/}
        <h2>{businessValueName}</h2>
        {businessValueById && businessValueById.length > 0 &&
            <CompBusinessValueObjectiveTree businessValueObject={businessValueById} />
            }  
      </>
      }

      {props.detailObjectType === "design-session" &&
      <>
        {/*<div>Test object1: {props.detailObjectId}</div>*/}
        <h2>{designSessionName}</h2>
        {designSessionById && designSessionById.length > 0 &&
          <>
            <div>Design Session ID{designSessionById[0].design_session_id}</div>
            <CompDesignSessionWorkspace designSessionId={designSessionById[0].design_session_id} /> 
          </>
        } 
      </>
      }
    </div>
  );
};

export default CompObjectDetail;