import React, { useState, useEffect } from "react";
import "./CompMilestones.css";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import CompTimelineMinimal from './CompTimelineMinimal';
import { Button } from "@mui/material";

const CompMilestones = ({ milestones, projectId }) => {
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30); // Start 30 days in the past
    return date;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 30); // End 30 days in the future
    return date;
  });

  const [dragging, setDragging] = useState(null);
  const [draggingEnd, setDraggingEnd] = useState(null); // For dragging the end handle

  const formatDate = (date) => date.toISOString().split("T")[0];

  const getTimelineDates = () => {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const timelineDates = getTimelineDates();

  const handleMouseDown = (milestoneIndex, type, event) => {
    const offsetX = event.clientX;
    if (type === "move") {
      setDragging({ milestoneIndex, type, offsetX });
    } else if (type === "extend") {
      setDraggingEnd({ milestoneIndex, offsetX });
    }
  };

  const handleMouseMove = (event) => {
    if (dragging) {
      const { milestoneIndex, type, offsetX } = dragging;
      const dx = event.clientX - offsetX;
      const daysMoved = Math.round(dx / 50); // Removed scale factor

      if (daysMoved !== 0) {
        const milestone = milestones[milestoneIndex];
        const updatedStartDate = new Date(milestone.dates[0]);
        updatedStartDate.setDate(updatedStartDate.getDate() + daysMoved);
        const updatedEndDate = new Date(milestone.dates[1]);
        updatedEndDate.setDate(updatedEndDate.getDate() + daysMoved);

        // Prevent overlapping milestones (ensure only one range per row)
        const overlappingMilestone = milestones.find((m, index) => index !== milestoneIndex &&
          new Date(m.dates[0]).toDateString() === updatedStartDate.toDateString());
        if (!overlappingMilestone) {
          milestones[milestoneIndex].dates = [updatedStartDate.toISOString().split("T")[0], updatedEndDate.toISOString().split("T")[0]];
          console.log(`Milestone ${milestones[milestoneIndex].label} new dates:`, [updatedStartDate, updatedEndDate]);

          // Extend the timeline range if necessary
          if (updatedStartDate < startDate) {
            setStartDate(updatedStartDate);
          }
          if (updatedEndDate > endDate) {
            setEndDate(updatedEndDate);
          }

          setDragging({ milestoneIndex, type, offsetX: event.clientX });
        }
      }
    } else if (draggingEnd) {
      const { milestoneIndex, offsetX } = draggingEnd;
      const dx = event.clientX - offsetX;
      const daysExtended = Math.round(dx / 50); // Removed scale factor

      if (daysExtended !== 0) {
        const milestone = milestones[milestoneIndex];
        const newEndDate = new Date(milestone.dates[1]);
        newEndDate.setDate(newEndDate.getDate() + daysExtended);

        // Prevent overlapping milestones (ensure only one range per row)
        const overlappingMilestone = milestones.find((m, index) => index !== milestoneIndex &&
          new Date(m.dates[0]).toDateString() === newEndDate.toDateString());
        if (!overlappingMilestone) {
          milestones[milestoneIndex].dates = [milestone.dates[0], newEndDate.toISOString().split("T")[0]];
          console.log(`Milestone ${milestones[milestoneIndex].label} extended to:`, [milestone.dates[0], newEndDate]);

          // Extend the timeline range if necessary
          if (newEndDate > endDate) {
            setEndDate(newEndDate);
          }

          setDraggingEnd({ milestoneIndex, offsetX: event.clientX });
        }
      }
    }
  };

  const handleMouseUp = () => {
    setDragging(null);
    setDraggingEnd(null);
  };

  useEffect(() => {
    if (dragging || draggingEnd) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, draggingEnd]);

  const [selectedIcons, setSelectedIcons] = useState("collapse-milestones");

  // Handle toggle change
  const handleToggle = (event, newSelection) => {
    if (newSelection === null) return;
    setSelectedIcons(newSelection);
    console.log('toggling selection: ' + newSelection);
  };
  

  return (
    <>
    <div className="fx-comp-milestones-toolbar">
      {/*<Button variant="outlined">Create Milestone</Button> */}
      {/*
        <ToggleButtonGroup
        value={selectedIcons}
        exclusive
        onChange={handleToggle}
        aria-label="text alignment"
        >
        <ToggleButton value="expand-milestones" aria-label="left aligned">
            <UnfoldMoreIcon />
        </ToggleButton>
        <ToggleButton value="collapse-milestones" aria-label="centered">
            <UnfoldLessIcon />
        </ToggleButton>
        </ToggleButtonGroup>

        <div>...</div>
        */}
    </div>
    {selectedIcons === "expand-milestones" &&
    <div className="gantt-chart-container">
      <div className="gantt-chart-timeline" style={{ display: "flex" }}>
        {timelineDates.map((date) => (
          <div
            key={date.toISOString()}
            className="gantt-chart-timeline-cell"
            style={{ minWidth: `50px` }} // Removed scale multiplication
          >
            {formatDate(date)}
          </div>
        ))}
      </div>

      <div className="gantt-chart-rows" style={{ width: `${timelineDates.length * 50}px` }}>
        {milestones.map((milestone, index) => {
          const startDateIndex = timelineDates.findIndex(
            (d) => d.toDateString() === new Date(milestone.dates[0]).toDateString()
          );
          const endDateIndex = timelineDates.findIndex(
            (d) => d.toDateString() === new Date(milestone.dates[1]).toDateString()
          );

          return (
            <div key={index} className="gantt-chart-row">
              {/* Render the milestone's range as a rectangle */}
              <div
                className="gantt-chart-milestone"
                onMouseDown={(event) => handleMouseDown(index, "move", event)}
                style={{
                  left: `${startDateIndex * 50}px`, // Removed scale multiplication
                  width: `${(endDateIndex - startDateIndex + 1) * 50}px`, // Removed scale multiplication
                }}
              >
                {milestone.label}
              </div>

              {/* Handle to extend milestone */}
              <div
                className="gantt-chart-handle-end"
                onMouseDown={(event) => handleMouseDown(index, "extend", event)}
                style={{
                  left: `${endDateIndex * 50 + 50 - 10}px`, // Removed scale multiplication
                }}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
    }

    {/*{selectedIcons === "collapse-milestones" && */}
        <div>
            <CompTimelineMinimal milestones={milestones} projectId={projectId} />
        </div>
    {/*}*/}
    </>
  );
};

export default CompMilestones;
