import React, { useState, useEffect, useContext } from 'react';
import './CompActionTracker.css';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { PanelContext } from '../Contexts/PanelContext';
import Slider from '@mui/material/Slider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { updateStatus } from '../fxAPI/fxStatusAPI';
import { getComStatusByOrgId, getComStatusTaskByUserId, getComStatusTaskByOrgId, getComStatusObjectiveByRequestorId, getComStatusObjectiveSubjectiveByRequestorId, getComStatusObjectiveByUserAssignedId, getComStatusObjectiveSubjectiveByUserAssignedId } from '../fxAPI/fxStatusAPI.js';
import Chip from '@mui/material/Chip';
import CompMiniAvatar from './CompMiniAvatar.jsx';

const CompActionTracker = (props) => {

  const [error, setError] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /* STATUS */
  const [statusByOrgId, setStatusByOrgData] = useState([]); // Initialize as an array
  /* get user status by orgId */
  const fxAPIFetchStatusByOrgId = async (orgId) => {
    await getComStatusByOrgId(orgId, setError, setStatusByOrgData);
    console.log('all status by org Id');
    console.dir(statusByOrgId);
  };

  /* STATUS */

  /* STATUS - OBJECTIVE BY USER ASSIGNED ID */
 const [comStatusObjectiveDataByUserAssigned, setComStatusObjectiveByUserAssignData] = useState([]); // Initialize as an array
 /* get status by user assigned */
 const fxAPIFetchComStatusObjectiveByUserAssignedId  = async (userId) => {
   await getComStatusObjectiveByUserAssignedId(userId, setError, setComStatusObjectiveByUserAssignData);
   console.log('all view status objective by user assigned Id');
   console.dir(comStatusObjectiveDataByUserAssigned);
 };

   /* STATUS - OBJECTIVE SUBJECTIVE BY USER ASSIGNED ID */
   const [comStatusObjectiveSubjectiveDataByUserAssigned, setComStatusObjectiveSubjectiveByUserAssignData] = useState([]); // Initialize as an array
   /* get status by user assigned */
   const fxAPIFetchComStatusObjectiveSubjectiveByUserAssignedId  = async (userId) => {
     await getComStatusObjectiveSubjectiveByUserAssignedId(userId, setError, setComStatusObjectiveSubjectiveByUserAssignData);
     console.log('all view status objective subjective by user assigned Id');
     console.dir(comStatusObjectiveSubjectiveDataByUserAssigned);
   };

 /* STATUS - OBJECTIVE BY REQUESTOR ID */
 const [comStatusObjectiveByRequestorData, setComStatusObjectiveByRequestorData] = useState([]); // Initialize as an array
 /* get status by requestor */
 const fxAPIFetchComStatusObjectiveByRequestorData  = async (userId) => {
   await getComStatusObjectiveByRequestorId(userId, setError, setComStatusObjectiveByRequestorData);
   console.log('all view status objective by user assigned Id');
   console.dir(comStatusObjectiveByRequestorData);
 };

  /* STATUS - OBJECTIVE SUBJECTIVE BY REQUESTOR ID */
  const [comStatusObjectiveSubjectiveByRequestorData, setComStatusObjectiveSubjectiveByRequestorData] = useState([]); // Initialize as an array
  /* get status by requestor */
  const fxAPIFetchComStatusObjectiveSubjectiveByRequestorData  = async (userId) => {
    await getComStatusObjectiveSubjectiveByRequestorId(userId, setError, setComStatusObjectiveSubjectiveByRequestorData);
    console.log('all view status objective subjective by user assigned Id');
    console.dir(comStatusObjectiveSubjectiveByRequestorData);
  };

  const resolveStatus = async (statusId, currentStatus) => {
      console.log('Triggered resolve status of: ' + currentStatus + ' for: ' + statusId);
  
      try {
        await handleUpdateStatus(statusId, currentStatus);
        console.log('Status updated successfully!');
        //handleRefreshElement();
      } catch (error) {
        console.error('Error updating status:', error);
  
        if (error.message.includes('Parse Error')) {
          console.error('Specific Parse Error occurred:', error);
        }
      } finally {
        // Ensure that the parent is refreshed, regardless of the error
        //props.refreshParent();
        const storedLocalUserID = localStorage.getItem("fxLocalUserID");
        fxAPIFetchComStatusObjectiveSubjectiveByUserAssignedId(storedLocalUserID);
        handleRefreshElement();
      }
    };
  
    const handleUpdateStatus = async (currentStatusId, statusCurrent) => {
      const statusId = currentStatusId;
      const title = null;
      const description = null;
      const related_org = null;
      const status_current = statusCurrent;
      const resolved = 'yes';
  
      try {
        // Simulate the API call to Oracle ADB
        await updateStatus(statusId, title, status_current, description, related_org, resolved);
        //handleRefreshElement();
      } catch (error) {
        throw new Error(`Failed to update status: ${error.message}`);
      }
    };

    /* Use Global Context to refresh Subjective Panel */
      const { triggerElementRefresh } = useContext(PanelContext);
    
      const handleRefreshElement = () => {
        console.log("Triggering refresh for activityPanel");
        triggerElementRefresh("activityPanel");
      };

 /* DATA GRID COLUMNS AND ROWS */

 /* My open updates */
 const columnsMyOpenUpdates = [
  { field: 'status_id', headerName: 'ID', width: 30 },
  { field: 'status_view_title', headerName: 'Title', width: 300 },
  {
    field: 'requestor',
    headerName: 'Requestor',
    width: 80,
    renderCell: (params) => (
      <CompMiniAvatar userId={params.row.requestor} />
    ),
  },
  { field: 'component_view_name', headerName: 'Objective', width: 250 },
  {
    field: 'date_created',
    headerName: 'Date Created',
    width: 125,
  },
  {
    field: 'response',
    headerName: 'Response',
    width: 300,
    renderCell: (params) => (
      <>
        <Chip className="fx-page-dashboard-action-pill-done" label="Done" onClick={() => resolveStatus(params.row.id, "Done")} /><Chip className="fx-page-dashboard-action-pill-working" label="Working" onClick={() => resolveStatus(params.row.id, "Working")} />
      </>
    ),
  }
];

// Map the API data to the action tracker rows
const rowsMyOpenUpdates = comStatusObjectiveSubjectiveDataByUserAssigned
  .filter((statusItem) => statusItem.resolved?.trim().toLowerCase() !== "yes")
  .map((item, index) => ({
    id: item.status_id || index, // Use status_id or index as the ID for each row
    status_view_title: item.status_view_title || 'N/A', // Ensure valid data
    requestor: item.requestor || 'N/A', // Ensure valid data
    component_view_name: item.component_view_name || 'N/A', // Ensure valid data
    date_created: item.status_view_date_time_created ? new Date(item.status_view_date_time_created) : null,
    response: '...' // Placeholder for other data
  }));

/* My resolved updates */
const columnsMyResolvedUpdates = [
  { field: 'status_id', headerName: 'ID', width: 30 },
  { field: 'status_view_title', headerName: 'Title', width: 300 },
  {
    field: 'requestor',
    headerName: 'Requestor',
    width: 80,
    renderCell: (params) => (
      <CompMiniAvatar userId={params.row.requestor} />
    ),
  },
  { field: 'component_view_name', headerName: 'Objective', width: 250 },
  {
    field: 'response',
    headerName: 'Response',
    width: 300,
    renderCell: (params) => (
      <>
      {params.row.status_current === "Done" &&
        <Chip className="fx-page-dashboard-action-pill-done" label="Done" />
      }
      {params.row.status_current === "Working" &&
        <Chip className="fx-page-dashboard-action-pill-working" label="Working" />
      }
      </>
    ),
  }
];

// Map the API data to the objective rows
const rowsMyResolvedUpdates = comStatusObjectiveSubjectiveDataByUserAssigned
  .filter((statusItem) => statusItem.resolved?.trim().toLowerCase() === "yes")
  .map((item, index) => ({
  id: item.status_id || index, // Use subjective_id as the ID for each row
  status_view_title: item.status_view_title || 'N/A', // Replace 'component_view_name' with the actual field from your data
  requestor: item.requestor || 'N/A', // Replace 'component_view_name' with the actual field from your data
  component_view_name: item.component_view_name || 'N/A', // Replace 'component_view_name' with the actual field from your data
  status_current: item.status_current || 'N/A',
  response: '...'
}));

/* My pending requests */
const columnsMyPendingRequests = [
  { field: 'status_id', headerName: 'ID', width: 30 },
  { field: 'status_view_title', headerName: 'Title', width: 300 },
  {
    field: 'user_assigned',
    headerName: 'Assigned To',
    width: 80,
    renderCell: (params) => (
      <CompMiniAvatar userId={params.row.requestor} />
    ),
  },
  { field: 'component_view_name', headerName: 'Objective', width: 250 }
];

// Map the API data to the objective rows
const rowsMyPendingRequests = comStatusObjectiveSubjectiveByRequestorData
.filter((statusItem) => statusItem.resolved?.trim().toLowerCase() !== "yes")
.map((item, index) => ({
  id: item.status_id || index, // Use subjective_id as the ID for each row
  status_view_title: item.status_view_title || 'N/A', // Replace 'component_view_name' with the actual field from your data
  user_assigned: item.user_assigned || 'N/A', // Replace 'component_view_name' with the actual field from your data
  component_view_name: item.component_view_name || 'N/A', // Replace 'component_view_name' with the actual field from your data
  status_current: item.status_current || 'N/A',
  response: '...'
}));

/* My resolved requests */
const columnsMyResolvedRequests = [
  { field: 'status_id', headerName: 'ID', width: 30 },
  { field: 'status_view_title', headerName: 'Title', width: 300 },
  {
    field: 'user_assigned',
    headerName: 'Assigned To',
    width: 80,
    renderCell: (params) => (
      <CompMiniAvatar userId={params.row.requestor} />
    ),
  },
  { field: 'component_view_name', headerName: 'Objective', width: 250 },
  {
    field: 'response',
    headerName: 'Response',
    width: 300,
    renderCell: (params) => (
      <>
      {params.row.status_current === "Done" &&
        <Chip className="fx-page-dashboard-action-pill-done" label="Done" />
      }
      {params.row.status_current === "Working" &&
        <Chip className="fx-page-dashboard-action-pill-working" label="Working" />
      }
      </>
    ),
  }
];

// Map the API data to the objective rows
const rowsMyResolvedRequests = comStatusObjectiveSubjectiveByRequestorData
.filter((statusItem) => statusItem.resolved?.trim().toLowerCase() === "yes")
.map((item, index) => ({
  id: item.status_id || index, // Use subjective_id as the ID for each row
  status_view_title: item.status_view_title || 'N/A', // Replace 'component_view_name' with the actual field from your data
  user_assigned: item.user_assigned || 'N/A', // Replace 'component_view_name' with the actual field from your data
  component_view_name: item.component_view_name || 'N/A', // Replace 'component_view_name' with the actual field from your data
  status_current: item.status_current || 'N/A',
  response: '...'
}));


 useEffect(() => {
  const currentOrgId = localStorage.getItem("fxLocalOrgID");
  //fxAPIFetchViewObjectivesProjectsByOrgId(currentOrgId);
  //fxAPIFetchTasksByOrgId(currentOrgId);

  fxAPIFetchStatusByOrgId(currentOrgId);
  //fxAPIFetchStatusTaskByOrgId(currentOrgId);
  const storedLocalUserID = localStorage.getItem("fxLocalUserID");
  console.log('get status by assigned for action tracker: ' + 'for: ' + storedLocalUserID);
  console.dir(comStatusObjectiveDataByUserAssigned);
  //fxAPIFetchComStatusObjectiveByUserAssignedId(storedLocalUserID);
  //fxAPIFetchComStatusObjectiveByRequestorData(storedLocalUserID);
  fxAPIFetchComStatusObjectiveSubjectiveByUserAssignedId(storedLocalUserID);
  fxAPIFetchComStatusObjectiveSubjectiveByRequestorData(storedLocalUserID);

  }, []);

  const paginationModel = { page: 0, pageSize: 5 };

  return (
    <>
    <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="My Open Updates" />
              <Tab label="My Resolved Updates" />
              <Tab label="My Pending Requests" />
              <Tab label="My Resolved Requests" />
              
    </Tabs>
    {tabValue === 0 && (

    <div>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rowsMyOpenUpdates}
          columns={columnsMyOpenUpdates}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
    </div>

    )}

    {tabValue === 1 && (

    <div>
    <Paper sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rowsMyResolvedUpdates}
        columns={columnsMyResolvedUpdates}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        sx={{ border: 0 }}
      />
    </Paper>
    </div>

    )}

    {tabValue === 2 && (

    <div>
    <Paper sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rowsMyPendingRequests}
        columns={columnsMyPendingRequests}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        sx={{ border: 0 }}
      />
    </Paper>
    </div>

    )}

    {tabValue === 3 && (

    <div>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rowsMyResolvedRequests}
          columns={columnsMyResolvedRequests}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
    </div>

    )}
    </>
  );
};

export default CompActionTracker;