import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import './PageTeam.css';
import CompTeamMemberList from '../Components/CompTeamMemberList';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';

const PageTeam = (props) => {
  


  return (
    <CompPageTempMaster3Panel headingOne="My Team">

      <CompTeamMemberList />
   
    </CompPageTempMaster3Panel>
  );
};

export default PageTeam;
