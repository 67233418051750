import React, { useState, useEffect } from 'react';
import './CompComponentDashboardItem.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const CompComponentDashboardItem = (props) => {

    const [tabValue, setTabValue] = useState(0);
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

  return (
    <div className='fx-comp-component-dash-item-wrap'>
        <div>
            <h4>Component: {props.componentName}</h4>
        </div>
        <div className='fx-comp-component-dash-item-row'>
        <div className='fx-comp-component-dash-item-image-portion'>
        {props.componentImage ? (
            <img src={props.componentImage} width="200px" height="200px" />
        ) : (
            <p>No image provided.</p>
        )}
        </div>
        <div className='fx-comp-component-dash-item-detail-portion'>
            <div>
            <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Description" />
                    {/*<Tab label="Manual Checks" />
                    <Tab label="Accessibility Tests" />*/}      
                    </Tabs>
                {tabValue === 0 && (
                    <>
                        
                        <div>
                            {props.componentDescription}
                        </div>  
                    </>
                )}
                {/*
                {tabValue === 1 && (
                    <>
                        <div>other component aspects future...</div> 
                    </>
                )}
                {tabValue === 2 && (
                    <>
                        <div>other component aspects future...</div> 
                    </>
                )}
                    */}
            </div>
        </div>
        </div>
    </div>
  );
};

export default CompComponentDashboardItem;