import React, { useState, useEffect, useContext } from 'react';
import './CompQuickCreateStatus.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getViewObjectivesProjectsByOrgId, getViewObjectivesProjectsByProjectId } from '../fxAPI/fxObjectivesAPI.js';
import { getSubjectivesObjectivesByProjectId } from '../fxAPI/fxSubjectivesAPI.js';
import { createActivityHistory } from '../fxAPI/fxActivityHistoryAPI.js';
import { getUsersByOrgId } from '../fxAPI/fxUsersAPI.js';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { createStatus } from '../fxAPI/fxStatusAPI.js';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { PanelContext } from '../Contexts/PanelContext';
import { useActivityHistory } from '../Contexts/ActivityHistoryContext';

const CompQuickCreateStatus = (props) => {

  const [error, setError] = useState(null);

  const [newUserId, setNewUserId] = useState(''); // Initialize with empty string
  const [newObjectiveId, setNewObjectiveId] = useState(''); // Initialize with empty string
  const [newTitle, setNewTitle] = useState(''); // Initialize with empty string
  const [newDescription, setNewDescription] = useState(''); // Initialize with empty string
  const [statusResponse, setStatusResponse] = useState(null);
  const [toggleType, setToggleType] = useState(null);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentObjectiveId, setCurrentObjectiveId] = useState(null);
  const [currentSubjectiveId, setCurrentSubjectiveId] = useState(null);

  const [viewObjectivesProjectsByOrg, setViewObjectivesProjectsByOrgData] = useState([]); // Initialize as an array
  const fxAPIFetchViewObjectivesProjectsByOrgId = async (orgId) => {
    await getViewObjectivesProjectsByOrgId(orgId, setError, setViewObjectivesProjectsByOrgData);
  };

  const [viewObjectivesProjectsByProject, setViewObjectivesProjectsByProjectData] = useState([]); // Initialize as an array

  const fxAPIFetchViewObjectivesProjectsByProjectId = async (projectId) => {
    await getViewObjectivesProjectsByProjectId(projectId, setError, setViewObjectivesProjectsByProjectData);
  };

  const [usersByOrgData, setUsersByOrgData] = useState([]); // Initialize as an array

  const fxAPIFetchUsersByOrgId = async (orgId) => {
    await getUsersByOrgId(orgId, setError, setUsersByOrgData);
  };

  const [projectsData, setProjectsData] = useState([]); // Initialize as an array

  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsData);
  };

  const [subjectivesByProjectData, setSubjectivesByProjectData] = useState([]); // Initialize as an array

  const fxAPIFetchSubjectivesObjectivesByProjectId = async (projectId) => {
    await getSubjectivesObjectivesByProjectId(projectId, setError, setSubjectivesByProjectData);
  };

  const handleUserChange = (e) => {
    setNewUserId(e.target.value);
  };

  const handleObjectiveChange = (e) => {
    setCurrentObjectiveId(e.target.value);
  };

  const handleSubjectiveChange = (e) => {
    setCurrentSubjectiveId(e.target.value);
  };

  const handleProjectChange = (e) => {
    setCurrentProjectId(e.target.value);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleToggleTypeChange = (e) => {
    setToggleType(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setNewDescription(e.target.value);
  };

  const handleCreateStatus = (event) => {
    event.preventDefault();
    const related_component = props.relatedComponentId;
    const related_org = localStorage.getItem("fxLocalOrgID");
    const related_project = currentProjectId || null;
    const user_assigned = newUserId;
    const requestor = localStorage.getItem("fxLocalUserID");
    const title = newTitle;
    const description = newDescription;
    if(toggleType === "objective" && !props.relatedComponentType) {
      var related_component_type = "objective";
    } else if(toggleType === "subjective" && !props.relatedComponentType) {
      var related_component_type = "subjective";
    } else {
      var related_component_type = props.relatedComponentType
    }

    
    createStatus(related_component, related_org, related_project, user_assigned, requestor, title, description, related_component_type, setStatusResponse);
    //handleClose();

    const historyRelatedOrg = localStorage.getItem("fxLocalOrgID");
    const historyMessage = "test message from status";
    const historyRelatedObjectType = "status";
    const historyRelatedObject = null;
    const historyCategory = null;
    const historyTags = null;
    const historyMetadata = null;
    const historyRelatedProject = related_project;
    const historyAuthoringUser = requestor;
    const historySource = "fluxity";

    console.log('Creating activity history');

    // Call createActivityHistory API to save the activity to the database
    createActivityHistory(historyMessage, historyRelatedObjectType, historyRelatedObject, historyCategory, historyTags, historyMetadata, historyRelatedOrg, historyRelatedProject, historyAuthoringUser, historySource);

    handleRefreshElement();
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    if (currentOrgId) {
      fxAPIFetchViewObjectivesProjectsByOrgId(currentOrgId);
      fxAPIFetchUsersByOrgId(currentOrgId);
      fxAPIFetchProjectsByOrgId(currentOrgId);
    }
    if(currentProjectId) {
      fxAPIFetchViewObjectivesProjectsByProjectId(currentProjectId);
      fxAPIFetchSubjectivesObjectivesByProjectId(currentProjectId);
    }
  }, [currentProjectId]);

  /* Use Global Context to refresh Activity Panel */
  const { triggerElementRefresh } = useContext(PanelContext);

  const handleRefreshElement = () => {
    console.log("Triggering refresh for activityPanel");
    triggerElementRefresh("activityPanel");
  };

  return (
    <Box p={3} className="fx-">
      <div className="uxc-debug">
      Related Component: {props.relatedComponentId}
      Item Type: {props.itemType}
      Related Component Type: {props.relatedComponentType}
      Related Component Name: {props.relatedComponentName}
      </div>
      <form className='fx-comp-quick-create-status-form' onSubmit={handleCreateStatus}>
        {/*
        <div>
          <FormControl fullWidth>
            <InputLabel id="status-from-label">Get Status From</InputLabel>
            <Select
              labelId="status-from-label"
              id="status-from-select"
              value={newUserId}
              label="Get Status From"
              onChange={handleUserChange}
            >
              {usersByOrgData.map((user) => (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {user.first_name} {user.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <br />
        */}
        <div>
              <div><label for="objective-select">Get Status From</label></div>
              <div>
                <select name="objective-select" id="objective-select" className='fx-control-custom-select' onChange={handleUserChange}>
                  {usersByOrgData.map((user) => (
                    <option value={user.user_id}>{user.first_name} {user.last_name}</option>
                  ))}
                </select>
              </div>
              </div>
        <br />
        
        {/*
        <div>
          <FormControl fullWidth>
            <InputLabel id="related-to-label">Related To</InputLabel>
            <Select
              labelId="related-to-label"
              id="related-to-select"
              value={newObjectiveId}
              label="Related To"
              onChange={handleObjectiveChange}
            >
              {viewObjectivesProjectsByOrg.map((viewObjective) => (
                <MenuItem key={viewObjective.objective_id} value={viewObjective.objective_id}>
                  {viewObjective.objective_view_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        */}
        {!props.relatedComponentId &&
        <>
        <div>
          <FormControl fullWidth>
            <InputLabel id="related-to-label">Related Project</InputLabel>
            <Select
              labelId="related-to-label"
              id="related-to-select"
              value={currentProjectId}
              label="Related To"
              onChange={handleProjectChange}
            >
              {projectsData.map((project) => (
                <MenuItem key={project.project_id} value={project.project_id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {!props.relatedComponentType && currentProjectId &&
        <div>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Objective / Subjective</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              onChange={handleToggleTypeChange}
            >
              <FormControlLabel value="objective" control={<Radio />} label="Objective" />
              <FormControlLabel value="subjective" control={<Radio />} label="Subjective" />
            </RadioGroup>
          </FormControl>
        </div>
        }
        {props.relatedComponentType === "objective" && 
          <div>Related Objective: {props.relatedComponentName}</div>
        }
        {props.relatedComponentType === "subjective" && 
          <div>Related Subjective: {props.relatedComponentName}</div>
        }
        <br />
        {toggleType === "objective" &&
        <>
          <div>
            <FormControl fullWidth>
              <InputLabel id="related-to-objective-label">Related Objective</InputLabel>
              <Select
                labelId="related-to-objective-label"
                id="related-to-objective-select"
                value={currentObjectiveId}
                label="Related To Objective"
                onChange={handleObjectiveChange}
              >
                {viewObjectivesProjectsByProject.map((objective) => (
                  <MenuItem key={objective.objective_id} value={objective.objective_id}>
                    {objective.objective_view_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </>
        }
        {toggleType === "subjective" &&
        <>
          <div>
            <FormControl fullWidth>
              <InputLabel id="related-to-subjective-label">Related Subjective</InputLabel>
              <Select
                labelId="related-to-subjective-label"
                id="related-to-subjective-select"
                value={currentSubjectiveId}
                label="Related To Subjective"
                onChange={handleSubjectiveChange}
              >
                {subjectivesByProjectData.map((subjective) => (
                  <MenuItem key={subjective.subjective_id} value={subjective.subjective_id}>
                    {subjective.subjective_view_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </>
        }
      </>
       } {/* end conditional for if component id is present */}
        <br />
        <div>
          <TextField id="outlined-basic" label="Title" variant="outlined" onChange={handleTitleChange} value={newTitle} />
        </div>
        <br />
        <div className="fx-comp-quick-create-status-text-area-description">
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={4}
            value={newDescription}
            onChange={handleDescriptionChange}
            fullWidth
          />  
        </div>
        <br />
        <Button variant='outlined' type="submit">Create Status</Button>     
      </form>
    </Box>
  );
};

export default CompQuickCreateStatus;