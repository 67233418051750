import React, { useEffect, useState, useCallback } from 'react';
import './PageFluxityCanvas.css';
import CompHeader from '../Components/CompHeader.jsx';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import CompGlobalTaskBar from '../Components/CompGlobalTaskBar.jsx';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  Handle,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getAnchorsObjectivesByProjectId } from '../fxAPI/fxAnchorsAPI.js';

const PageFluxityCanvas = () => {
  const [error, setError] = useState(null);
  const [anchorsObjectivesByProject, setAnchorsObjectivesByProjectData] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [currentProject, setCurrentProject] = React.useState('');

  const handleChangeProject = (event) => {
    setCurrentProject(event.target.value);
  };

  /* PROJECTS */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array

  /* get user personas by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchProjectsByOrgId(currentOrgId);
  }, []);

  // Custom node definition
  const CustomNode = ({ data }) => (
    <div style={{ padding: 10, border: '1px solid black', borderRadius: 5, background: '#fff' }}>
      {data.label}
      <Handle type="target" position="top" />
      <Handle type="source" position="bottom" />
    </div>
  );

  // Fetch data from API
  const fxAPIFetchAnchorsObjectivesByProjectId = async (projectId) => {
    await getAnchorsObjectivesByProjectId(projectId, setError, setAnchorsObjectivesByProjectData);
  };

  // Generate nodes and edges from dataset
  useEffect(() => {
    if (Object.keys(anchorsObjectivesByProject).length > 0) {
      const newNodes = [];
      const newEdges = [];
      const horizontalSpacing = 300;
      const yPosition = 100;

      Object.entries(anchorsObjectivesByProject).forEach(([project, details], index) => {
        const nodeId = `project-${index}`;
        const nodePosition = { x: index * horizontalSpacing, y: yPosition };
        const anchorName = details?.anchor_name || 'Unknown';

        newNodes.push({
          id: nodeId,
          data: { label: `${project}: ${anchorName}` },
          position: nodePosition,
          type: 'custom',
        });

        if (index > 0) {
          newEdges.push({
            id: `edge-${index - 1}-${index}`,
            source: `project-${index - 1}`,
            target: nodeId,
            type: 'smoothstep',
          });
        }
      });

      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [anchorsObjectivesByProject]);

  // Add a new node dynamically
  const addNewNode = () => {
    const newNodeId = `project-${nodes.length}`;
    const newNode = {
      id: newNodeId,
      data: { label: `New Node ${nodes.length}` },
      position: { x: Math.random() * 800, y: Math.random() * 400 }, // Random position for demo
      type: 'custom',
    };
    setNodes((nds) => [...nds, newNode]);
  };

  // Handle new connections
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, type: 'smoothstep' }, eds)),
    [setEdges]
  );

  // Fetch anchors on component mount
  useEffect(() => {
    fxAPIFetchAnchorsObjectivesByProjectId(currentProject);
  }, [currentProject]);

  return (
    <div>
      <CompHeader />
      {/*<CompGlobalTaskBar />*/}
      <div className='fx-comp-canvas-toolbar-wrap'>
        <div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Playbook</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentProject}
              label="Age"
              onChange={handleChangeProject}
            >
              {projectsByOrgId.map((projectItem) => (
                <MenuItem key={projectItem.project_id} value={projectItem.project_id}>
                  {projectItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <button onClick={addNewNode} style={{ padding: '8px 16px', marginTop: '10px' }}>
          Add New Node
        </button>
      </div>
      <div style={{ height: '100vh', backgroundColor: '#f8f9fa' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={{ custom: CustomNode }}
          fitView
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
};

export default PageFluxityCanvas;
