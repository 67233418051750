import { io } from 'socket.io-client';

let socket = null;

const initializeSocket = () => {
  if (!socket) {
    socket = io('https://fluxity.io'); // Replace with your socket server URL
  }
  return socket;
};

const getSocket = () => {
  return socket;
};

const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};

export { initializeSocket, getSocket, disconnectSocket };