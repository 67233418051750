import React, { useState, useEffect } from 'react';
import './CompJourneyAddMenu.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Popover from '@mui/material/Popover';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CompCRUDModal from './CompCRUDModal';
import CompModalJourneyAssignUI from './CompModalJourneyAssignUI';
import CompModalAnchorLinkComponents from './CompModalAnchorLinkComponents';
import CompModalAnchorLinkDevices from './CompModalAnchorLinkDevices';

const CompJourneyAddMenu = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log('clicked popover for: : ' + props.relatedAnchorId);
      };

  return (
    <div>
        {/*<CompCRUDModal isOpen={isOpen} onClose={closeModal} closeModal={closeModal} relatedComponentId={props.relatedObjectId} modalTitle={actionModalTitle} itemType={props.relatedObjectType} crudMethod={crudMethod} modalComponentData={props.componentData}></CompCRUDModal>*/}
        <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                className="fx-comp-journey-add-popover-container"
                PaperProps={{
                  style: {
                    padding: '16px', // Adjust padding
                    minWidth: '200px' // Adjust min-width
                  },
                }}
              >
                <div className="fx-comp-quick-edit-popover-item"><CompModalAnchorLinkComponents relatedAnchorId={props.relatedAnchorId} relatedProjectId={props.relatedProjectId} /></div>
                <div className="fx-comp-quick-edit-popover-item"><CompModalJourneyAssignUI relatedAnchorId={props.relatedAnchorId} relatedProjectId={props.relatedProjectId} /></div>
                <div className="fx-comp-quick-edit-popover-item"><CompModalAnchorLinkDevices relatedAnchorId={props.relatedAnchorId} relatedProjectId={props.relatedProjectId} /></div>
                {/*
                <div className="fx-comp-quick-edit-popover-item" onClick={() => openModal("view-edit")}>Add Component</div>
                <div className="fx-comp-quick-edit-popover-item" onClick={() => openModal("delete")}>Add UI Asset</div>
                <div className="fx-comp-quick-edit-popover-item" onClick={() => openModal("delete")}>Add Device</div>
                */}
        </Popover>
      <div className='fx-comp-swimlane-flow-add-container'>
        <div onClick={handleClick}><AddCircleOutlineIcon /></div>
      </div> 
    </div>
  );
};

export default CompJourneyAddMenu;