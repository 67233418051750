import React, { useEffect, useState } from 'react';
import './PageHome.css';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import { getUIAssetById } from '../fxAPI/fxUIAssetsAPI.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function PageUIAssetDetail() {

  const params = useParams();

  const [error, setError] = useState(null);

  const [currentVersion, setCurrentVersion] = React.useState('');

  const handleVersionChange = (event) => {
    setCurrentVersion(event.target.value);
    //fxAPIFetchUIAssetsByProjectId(event.target.value);
  };

  const [tabValue, setTabValue] = useState(0);
  
  const uiAssetMessage = 'UI Asset Detail';

  /* UI Assets */
  const [uiAssetById, setUIAssetByIdData] = useState([]); // Initialize as an array
  /* get UI Assets by orgId */
  const fxAPIFetchUIAssetById = async (id) => {
    await getUIAssetById(id, setError, setUIAssetByIdData);
    console.log('ui asset by Id');
    console.dir(uiAssetById);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchUIAssetById(params.compId);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const currentVersionHeading = () => {
    if(currentVersion) {
      var versionHeading = "Version " + currentVersion;
    } else {
      var versionHeading = "Latest Version";
    }
    return versionHeading;
  };  

  const viewVersionHeading = currentVersionHeading();

  

  return (
    <CompPageTempMaster3Panel headingOne={uiAssetMessage}>
      <CompContentBlock headingThree="Current UI Asset">
        {uiAssetById.map((uiAssetItem) => (
          <>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="UI Asset" />
              {/*
              <Tab label="Status" />
              */}
            </Tabs>
            {tabValue === 0 && (
              <>
                <h3>Image</h3>
                <Box p={3} className="fx-">
                      <img width="100%" height="auto" src={uiAssetItem.img_full_url} />
                </Box>
              </>
            )}
            {/*
            {tabValue === 1 && (
              <Box p={3} className="fx-">
                    Status list ...
              </Box>
            )}
            */}
          </>
        ))}
      </CompContentBlock>
    </CompPageTempMaster3Panel>
  );
}