import React, { useEffect, useState, useContext } from 'react';
import './CompCRUDFormTeamMember.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createUser, updateUser } from '../fxAPI/fxUsersAPI.js';
import { PanelContext } from '../Contexts/PanelContext';

const CompCRUDFormTeamMember = (props, handleClose) => {

  const [username, setUsername] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState(null);
  const [userResponse, setUserResponse] = useState(null);
  const [currentFirstName, setCurrentFirstName] = useState('');
  const [currentLastName, setCurrentLastName] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [updateFirstName, setUpdateFirstName] = useState(props.currentComponentData?.first_name || '');
  const [updateLastName, setUpdateLastName] = useState(props.currentComponentData?.last_name || '');
  const [updateRole, setUpdateRole] = useState(props.currentComponentData?.role || '');
  const [updateUsername, setUpdateUsername] = useState(props.currentComponentData?.username || '');
  const [updateEmailAddress, setUpdateEmailAddress] = useState(props.currentComponentData?.email_address || '');
  const { triggerElementRefresh } = useContext(PanelContext);
  

  console.log('current team member data on form team member: ');
  console.dir(props.currentComponentData);

  const handleCreateUser = async (event) => {
    try {
      event.preventDefault();
  
      // Get values from localStorage and state
      const relatedOrg = localStorage.getItem("fxLocalOrgID");
      const role = currentRole;
      const firstName = currentFirstName;
      const lastName = currentLastName;
  
      console.log("Submitting user creation with data:", {
        username,
        emailAddress,
        relatedOrg,
        firstName,
        lastName,
        role,
      });
  
      // Call the createUser API
      const response = await createUser(
        username,
        emailAddress,
        relatedOrg,
        firstName,
        lastName,
        null,
        role,
        setError,
        setUserResponse
      );
  
      // Check if the API response is successful
      if (response?.status === 200 || response?.status === 201) {
        console.log("User created successfully");
      } else {
        throw new Error(
          `Server responded with status ${response?.status || "unknown"}`
        );
      }
  
      // Trigger refresh and close modal regardless of outcome
      console.log("Triggering team list refresh");
      triggerElementRefresh("teamList");
      handleClose("teamList");
    } catch (error) {
      // Log the error and set a user-friendly error message
      console.error("Error during user creation:", error);
      setError("There was an issue creating the user. Please try again later.");
  
      // Ensure the modal still closes and the list refreshes for consistency
      console.log("Refreshing team list and closing modal even after error");
      triggerElementRefresh("teamList");
      handleClose("teamList");
    }
  };
  

  const [currentEditableState, setCurrentEditableState] = useState('view');

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleFirstNameChange = (e) => {
    setCurrentFirstName(e.target.value);
  }

  const handleLastNameChange = (e) => {
    setCurrentLastName(e.target.value);
  }

  const handleRoleChange = (e) => {
    setCurrentRole(e.target.value);
  }

  const handleUpdateUser = async (e) => {
    console.log('ran update user: ' + props.currentComponentData.user_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const userId = props.currentComponentData.user_id;
    const username = updateUsername;
    const emailAddress = updateEmailAddress;
    const relatedOrgId = relatedOrg;
    const firstName = updateFirstName;
    const lastName = updateLastName;
    const relatedAvatar = '';
    const role = updateRole;
  
    await updateUser(userId, username, emailAddress, relatedOrgId, firstName, lastName, relatedAvatar, role);
    // Trigger parent refresh
    //console.log('attempt refresh on team list from team CRUD');
    //triggerElementRefresh('teamList');
    props.handleClose();
  };

  return (
    <div>
        {props.crudMethod === "create" &&
          <div>
          <form onSubmit={handleCreateUser}>
            <div>
                  <TextField id="outlined-basic" label="First Name" variant="outlined" value={currentFirstName} onChange={handleFirstNameChange} />
            </div>  
          <br />
          <div>
                  <TextField id="outlined-basic" label="Last Name" variant="outlined" value={currentLastName} onChange={handleLastNameChange} />
            </div>  
          <br />
          <div>
              <label for="roles">Role</label>
              <select name="roles" id="roles" value={currentRole} onChange={handleRoleChange}>
                  <option value="">Select Role...</option>
                    <option value="designer">Designer</option>
                    <option value="developer">Developer</option>
                    <option value="product-owner">Product Owner</option>
                    <option value="executive">Executive</option>
                    <option value="end-user">End User</option>
              </select>
          </div>
            <div>
              <TextField id="outlined-basic" label="Username" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <br />
            <div>
              <TextField id="outlined-basic" label="Email Address" variant="outlined" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} /> 
            </div>
            
            <CompModalButtons>
                  <Button type="submit">Create User</Button>
                </CompModalButtons>  
          </form>

          {error && <div style={{ color: 'red' }}>{error}</div>}
          {userResponse && (
            <div>
              <h3>User Created Successfully:</h3>
              <pre>{JSON.stringify(userResponse, null, 2)}</pre>
            </div>
          )}
          </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>First Name</div>
                <div>{props.currentComponentData.first_name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Last Name</div>
                <div>{props.currentComponentData.last_name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Email</div>
                <div>{props.currentComponentData.email_address}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Role</div>
                <div>{props.currentComponentData.role}</div>
              </div>
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateUser}>
                <div>
                  <TextField id="outlined-basic" label="First Name" value={updateFirstName} variant="outlined" onChange={(e) => setUpdateFirstName(e.target.value)} />
                </div>   
                <br />
                <div>
                  <TextField id="outlined-basic" label="Last Name" value={updateLastName} variant="outlined" onChange={(e) => setUpdateLastName(e.target.value)} />
                </div> 
                <br />
                <div>
                  <TextField id="outlined-basic" label="Username" value={updateUsername} variant="outlined" onChange={(e) => setUpdateUsername(e.target.value)} />
                </div> 
                <br />
                <div>
                    <label for="roles">Role</label>
                    <select name="roles" id="roles" value={updateRole} onChange={(e) => setUpdateRole(e.target.value)}>
                        <option value="">Select Role...</option>
                          <option value="designer">Designer</option>
                          <option value="developer">Developer</option>
                          <option value="product-owner">Product Owner</option>
                          <option value="executive">Executive</option>
                          <option value="end-user">End User</option>
                    </select>
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Email" value={updateEmailAddress} variant="outlined" onChange={(e) => setUpdateEmailAddress(e.target.value)} />
                </div> 
                <CompModalButtons>
                  <Button type="submit">Save Team Member</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormTeamMember;