import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import './PagePlaybookJourneyMap.css';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompJourneyMap from '../Components/CompJourneyMap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompUIAssetBulkProcessor from '../Components/CompUIAssetBulkProcessor';

const PagePlaybookJourneyMap = (props) => {
  
    const params = useParams();

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

  return (
    <CompPageTempMaster3Panel headingOne="Journey Map">

      <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Journey" />
              <Tab label="Add Journey Images" />
              
      </Tabs>

      {tabValue === 0 && (
              <CompJourneyMap relatedProject={params.projectId} /> 
      )}

      {tabValue === 1 && (
              <CompUIAssetBulkProcessor context="anchor" relatedProject={params.projectId} />
      )}

      
   
    </CompPageTempMaster3Panel>
  );
};

export default PagePlaybookJourneyMap;