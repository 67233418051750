import React from 'react';
import './CompAccessibilityListItem.css';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';

const CompAccessibilityListItem = (props) => {

  return (
    <div>
      <div className='fx-comp-acc-list-item-wrap'>
        <div className='fx-comp-acc-list-item-title-row'>
            <div className='fx-comp-acc-list-item-title-row-text-item'>{props.guidelineText}</div>
            <div className='fx-comp-acc-list-item-title-row-icon-item'>
            <Tooltip title={props.helpText}>
                <IconButton>
                    <HelpIcon />
                </IconButton>
            </Tooltip>
            </div>
        </div>
        <div>
            {props.subText}
        </div>
      </div>
    </div>
  );
};

export default CompAccessibilityListItem;