import React, { useEffect, useState } from 'react';
import './CompSideNavMaster.css';
import CompNavItem from './CompNavItem.jsx';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI';
import { getObjectivesByOrgId } from '../fxAPI/fxObjectivesAPI';
import { getUserPersonasByOrgId } from '../fxAPI/fxUserPersonasAPI.js';
import { getUIAssetsByOrgId } from '../fxAPI/fxUIAssetsAPI.js';
import { getComStatusByOrgId } from '../fxAPI/fxStatusAPI.js';
import { getReportsByOrgId } from '../fxAPI/fxReportsAPI.js';
import Button from '@mui/material/Button';
import CompCollapsibleContainer from './CompCollapsibleContainer.jsx';


const CompSideNavMaster = (props) => {
  const [error, setError] = useState(null);

  // Hide some aspects for production users temporarily
  const localEnv = localStorage.getItem('fxEnv');
  console.log('local environment on header: ' + localEnv);

  const [projects, setProjectsData] = useState([]); // Initialize as an array
  const [refinedProjects, setRefinedProjects] = useState([]);

  /* get projects by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsData);
    console.log('all projects by org Id');
    console.dir(projects);
  };

  const [objectives, setObjectivesData] = useState([]); // Initialize as an array
  /* get objectives by orgId */
  const fxAPIFetchObjectivesByOrgId = async (orgId) => {
    await getObjectivesByOrgId(orgId, setError, setObjectivesData);
    console.log('all objectives by org Id');
    console.dir(objectives);
  };

  const [userPersonas, setUserPersonasData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUserPersonasByOrgId = async (orgId) => {
    await getUserPersonasByOrgId(orgId, setError, setUserPersonasData);
  };

  const [uiAssets, setUIAssetsData] = useState([]); // Initialize as an array
  /* get ui assets by orgId */
  const fxAPIFetchUIAssetsByOrgId = async (orgId) => {
    await getUIAssetsByOrgId(orgId, setError, setUIAssetsData);
  };

  const [comStatus, setComStatusData] = useState([]); // Initialize as an array
  /* get status by orgId */
  const fxAPIFetchComStatusByOrgId = async (orgId) => {
    await getComStatusByOrgId(orgId, setError, setComStatusData);
  };

  const [reports, setReportsData] = useState([]); // Initialize as an array
  /* get Reports by orgId */
  const fxAPIFetchReportsByOrgId = async (orgId) => {
    await getReportsByOrgId(orgId, setError, setReportsData);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    if (currentOrgId) {
      console.log(`Current OrgId from local storage: ${currentOrgId}`);
      fxAPIFetchProjectsByOrgId(currentOrgId);
      fxAPIFetchObjectivesByOrgId(currentOrgId);
      fxAPIFetchUserPersonasByOrgId(currentOrgId);
      fxAPIFetchUIAssetsByOrgId(currentOrgId);
      fxAPIFetchComStatusByOrgId(currentOrgId);
      fxAPIFetchReportsByOrgId(currentOrgId);
    } else {
      setError('No OrgId found in local storage');
    }
  }, []);

  useEffect(() => {
    console.log('Projects state updated:', projects);
    
  }, [projects]);

  // Sum States
  const [projectsTotal, setProjectsTotal] = useState(null);
  const [objectivesTotal, setObjectivesTotal] = useState(null);
  const [userPersonasTotal, setUserPersonasTotal] = useState(null);
  const [comStatusTotal, setComStatusTotal] = useState(null);
  const [uiAssetsTotal, setUIAssetsTotal] = useState(null);

  useEffect(() => {
    // Counting Projects
    let projectsCount = 0;
    projects.forEach((project) => {
      projectsCount += 1;
    });
    setProjectsTotal(projectsCount);

    // Counting Objectives
    let objectivesCount = 0;
    objectives.forEach((objective) => {
      objectivesCount += 1;
    });
    setObjectivesTotal(objectivesCount);

    // Counting User Personas
    let userPersonasCount = 0;
    userPersonas.forEach((userPersona) => {
      userPersonasCount += 1;
    });
    setUserPersonasTotal(userPersonasCount);

    // Counting Status
    let statusCount = 0;
    comStatus.forEach((status) => {
      statusCount += 1;
    });
    setComStatusTotal(statusCount);

    // Counting UI Assets
    let uiAssetsCount = 0;
    uiAssets.forEach((uiAsset) => {
      uiAssetsCount += 1;
    });
    setUIAssetsTotal(uiAssetsCount);

  }, [projects, objectives, userPersonas, comStatus, uiAssets]);

  console.log('Objectives sum: ' + objectivesTotal);

  const localUserRole = localStorage.getItem("fxLocalUserRole");

  

  return (
    <div>
      {/*{props.isCollapsed && */}
      {localUserRole === 'end-user' &&
        <>
        <h2>User Tools</h2>
        <div className="fx-comp-side-nav-master-link-primary-group-item">
          <a href="/end-user-tools">
            <div className="fx-comp-side-nav-master-link-primary-group-item-inner">User Home</div>
          </a>
        </div> 
        </>     
      }
      {localUserRole !== 'end-user' &&
      <>
        <>
          <h2>Business Value</h2>
          <div className="fx-comp-side-nav-master-link-primary-group-item">
            <a href="/business-value">
              <div className="fx-comp-side-nav-master-link-primary-group-item-inner">Business Value</div>
            </a>
          </div>
          <h2>Playbooks</h2>
          {projects.map((project) => (
                  <div className="fx-comp-side-nav-master-link-primary-group-item nested">
                    <CompCollapsibleContainer containerHeading={project.name}>
                      <div>
                        <a href={`/playbook/objective-map/${project.project_id}`}>Playbook Overview</a>
                      </div>
                      <div>
                        <a href={`/playbook/objective-map/${project.project_id}`}>Objective Map</a>
                      </div>
                      <div>
                        <a href={`/playbook/objective-stack/${project.project_id}`}>Objective Stack</a>
                      </div>
                      <div>
                        <a href={`/playbook/journey-map/${project.project_id}`}>Journey Map</a>
                      </div>
                      <div>
                        <a href="/ui-assets/">UI Assets</a>
                      </div>
                    </CompCollapsibleContainer>
                    {/*
                    <a href={`/dashboard/projects/${project.project_id}`} key={project.project_id}>
                      <div className="fx-comp-side-nav-master-link-primary-group-item-inner">{project.name}</div>
                    </a>
                    */}
                  </div>
                  
          ))}
          
        </>
      {/*} */}
      <div>
        <h2>Design</h2>
        <div className="fx-comp-side-nav-master-link-primary-group-item">
          <a href="/ui-assets">
            <div className="fx-comp-side-nav-master-link-primary-group-item-inner">UI Assets</div>
          </a>
        </div>
        {localEnv !== 'production' &&
        <div className="fx-comp-side-nav-master-link-primary-group-item">
          <a href="/design-sessions">
            <div className="fx-comp-side-nav-master-link-primary-group-item-inner">Design Sessions</div>
          </a>
        </div>
        }     
        <div className="fx-comp-side-nav-master-link-primary-group-item">
          <a href="/components">
            <div className="fx-comp-side-nav-master-link-primary-group-item-inner">Components</div>
          </a>
        </div>
        <div className="fx-comp-side-nav-master-link-primary-group-item">
          <a href="/accessibility">
            <div className="fx-comp-side-nav-master-link-primary-group-item-inner">Accessibility</div>
          </a>
        </div>
      </div>
      </>
      }
    </div>
  );
};

export default CompSideNavMaster;