import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0RedirectURI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const envPrint = process.env.NODE_ENV;


console.log("Auth0 Domain:", auth0Domain);
console.log("Auth0 Client ID:", auth0ClientId);
console.log("Auth0 Redirect URI:", auth0RedirectURI);
console.log("Environment: " + envPrint);
localStorage.setItem("fxEnv", envPrint);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
  domain='dev-66zsem5kdoiwk52q.us.auth0.com'
  clientId={auth0ClientId}
  authorizationParams={{
    redirect_uri: auth0RedirectURI
    //redirect_uri: window.location.origin + '/callback'
  }}
> 
    <App />
</Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

