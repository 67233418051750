import React, { useState, useEffect, useCallback, useContext } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './CompObjectiveAnchors.css';
import { createAnchor, getAnchorsByProjectId, getAnchorsObjectivesByProjectId } from '../fxAPI/fxAnchorsAPI.js';
import { getObjectivesByProjectIdAndAnchorId, updateObjective } from '../fxAPI/fxObjectivesAPI.js';
import { getUIAssetsByAnchorId } from '../fxAPI/fxUIAssetsAPI.js';
import Button from '@mui/material/Button';
import CompObjectiveAnchorCard from './CompObjectiveAnchorCard.jsx';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CompAnchorToolbar from './CompAnchorToolbar.jsx';
import RectangleIcon from '@mui/icons-material/Rectangle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SettingsIcon from '@mui/icons-material/Settings';
import FlagIcon from '@mui/icons-material/Flag';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import EditIcon from '@mui/icons-material/Edit';
import CompQuickActionsPopover from './CompQuickActionsPopover.jsx';
import { getPathsByProjectId } from '../fxAPI/fxPathsAPI';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CompLinkObjectToInt from './CompLinkObjectToInt';
import PageviewIcon from '@mui/icons-material/Pageview';
import { PanelContext } from '../Contexts/PanelContext';
import CompMilestones from './CompMilestones.jsx';
import CompCRUDModal from './CompCRUDModal.jsx';
import CompHelpBanner from './CompHelpBanner.jsx';

const ItemTypes = {
  CARD: 'card',
};

// Card Component
const KanbanCard = ({ card, index, currentAnchorId, moveCard, relatedProject }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  const [modalComponentData, setModalComponentData] = useState([]);
  const [relatedComponentId, setRelatedComponentId] = useState(null);
  const [relatedComponentName, setRelatedComponentName] = useState(null);
  const [relatedComponentType, setRelatedComponentType] = useState(null);

  const openModal = (itemType, currentComponentId, currentComponentName, currentComponentType, currentCRUDMethod, currentModalComponentData) => {
    setItemType(itemType);
    setRelatedComponentId(currentComponentId);
    setRelatedComponentName(currentComponentName);
    setRelatedComponentType(currentComponentType);
    setCRUDMethod(currentCRUDMethod);
    setModalComponentData(currentModalComponentData);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { card, index, currentAnchorId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  console.log('card component data for object: ');
  console.dir(card.componentData);

  /* Use Global Context to refresh Subjective Panel */
  const { triggerElementRefresh } = useContext(PanelContext);

  const handleRefreshElement = () => {
    triggerElementRefresh('sideContextPanel'); // Trigger refresh for the subjectivePanel
  };

  const handleSwitchPanelContext = (object_id, type, object) => {
    //updatePanelContext("objective", objective_id, object);
    localStorage.setItem("fxSidePanelContext", type);
    localStorage.setItem("fxSidePanelObjectId", object_id);
    localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
    handleRefreshElement();
  };

  const localEnv = localStorage.getItem('fxEnv');

  return (
    <>
    <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={actionModalTitle} itemType={itemType} crudMethod={crudMethod} modalComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedComponentName={relatedComponentName} relatedComponentType={relatedComponentType}></CompCRUDModal>
    <div
      className={`card ${isDragging ? 'dragging' : ''}`}
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="fx-objective-card-title-row">
        <div className="fx-objective-card-title-row-title-portion">
          <strong>{card.name}</strong></div>
          <div className="fx-objective-card-title-row-button-portion">
          {localEnv !== 'production' &&
            <CompLinkObjectToInt relatedObjectType="objective" relatedObjectId={card.id}  relatedProject={relatedProject} componentData={card.componentData} />
          }
            <CompQuickActionsPopover relatedObjectType="objective" relatedObjectId={card.id}  componentData={card.componentData} />
            {/*<div><CompQuickCreateStatusModal /></div>*/}
            <div onClick={() => openModal("status", card.id, card.name, "objective", "create", card.componentData)}><FlagIcon /></div>
            <div onClick={() => handleSwitchPanelContext(card.id, "objective", card.componentData)}><PageviewIcon /></div>
          </div>
      </div>
      {/*      
      ID: {card.id}
      Objective ID: {card.objective_id}
      */}
      {card.content}
      <CompObjectiveAnchorCard relatedProject={relatedProject} relatedObjective={card.id} />
    </div>
    </>
  );
};

// Anchor (Column) Component
const Anchor = ({ anchor, cards, moveCard, relatedProject }) => {

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => true,
    drop: (item) => {
      if (item.currentAnchorId !== anchor.id) {
        moveCard(item.index, item.currentAnchorId, anchor.id);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  

  const isActive = canDrop && isOver;
  const backgroundColor = isActive ? '#d4f1f9' : '#f4f4f4';

  return (
    <div className="anchor-container" ref={drop} style={{ backgroundColor }}>
      <CompAnchorToolbar anchorId={anchor.id} relatedProject={relatedProject} />

      <div className="fx-comp-objective-anchor-title-row">
          {anchor.type === "assignment" &&
            <div className="fx-comp-objective-anchor-type-icon assignment"><PersonalVideoIcon /></div>
          }
          {anchor.type !== "assignment" &&
            <div className="fx-comp-objective-anchor-type-icon decision"><AccountTreeIcon /></div>
          }
        <h3 className="fx-comp-objective-anchor-title">
          {anchor.name}
        </h3>
      </div>
      <div className="fx-comp-objective-anchor-title-type">{anchor.type}</div>
      

      {cards.map((card, index) => (
        <KanbanCard
          key={card.id}
          card={card}
          index={index}
          currentAnchorId={anchor.id}
          moveCard={moveCard}
          relatedProject={relatedProject}
        />
      ))}
    </div>
  );
};

const CompObjectiveAnchors = (props) => {

  const [error, setError] = useState(null);

  /* ANCHORS */
  const [anchorsByProject, setAnchorsByProjectData] = useState([]);
  const fxAPIFetchAnchorsByProjectId = async (projectId) => {
    await getAnchorsByProjectId(projectId, setError, setAnchorsByProjectData);
  };

  /* ANCHORS OBJECTIVES BY PROJECT ID */
  const [anchorsObjectivesByProject, setAnchorsObjectivesByProjectData] = useState([]);
  const fxAPIFetchAnchorsObjectivesByProjectId = async (projectId) => {
    await getAnchorsObjectivesByProjectId(projectId, setError, setAnchorsObjectivesByProjectData);
  };

  /* OBJECTIVES BY PROJECT AND ANCHOR */
  const [objectivesByProjectAndAnchor, setObjectivesByProjectAndAnchorData] = useState([]);
  const fxAPIFetchObjectivesByProjectAndAnchorId = async (projectId, anchorId) => {
    await getObjectivesByProjectIdAndAnchorId(projectId, anchorId, setError, setObjectivesByProjectAndAnchorData);
  };

  /* UI Assets by anchor id */
  const [uiAssetsByAnchorId, setUIAssetsByAnchorData] = useState([]); // Initialize as an array
  /* get UI Assets by projectId */
  const fxAPIFetchUIAssetsByAnchorId = async (anchorId) => {
    await getUIAssetsByAnchorId(anchorId, setError, setUIAssetsByAnchorData);
    console.log('all ui assets by anchor Id');
    console.dir(uiAssetsByAnchorId);
  };

  /* PATHS BY PROJECT ID */
  const [pathsByProjectId, setPathsByProjectData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchPathsByProjectId = async (projectId) => {
    await getPathsByProjectId(projectId, setError, setPathsByProjectData);
    console.log('all paths by project Id');
    console.dir(pathsByProjectId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchAnchorsByProjectId(props.relatedProject);
    fxAPIFetchObjectivesByProjectAndAnchorId(props.relatedProject, '1');
    fxAPIFetchAnchorsObjectivesByProjectId(props.relatedProject);
    fxAPIFetchPathsByProjectId(props.relatedProject);
  }, [props.relatedProject]);

  const [anchors, setAnchors] = useState([]);

  useEffect(() => {
    const updatedAnchors = anchorsByProject.map((anchor, index) => {
      const relatedObjectives = anchorsObjectivesByProject
        .filter(obj => obj.related_anchor === anchor.anchor_id)
        .map((obj) => ({
          id: obj.objective_id,
          name: obj.objective_name,
          description: obj.objective_description,
          componentData: obj
        }));

      return {
        id: anchor.anchor_id || `anchor-${index + 1}`,
        name: anchor.name || `Anchor ${index + 1}`,
        position: anchor.position || index + 1,
        type: anchor.type,
        cards: relatedObjectives
        //cards: relatedObjectives.length > 0 ? relatedObjectives : [{ id: `card-${index + 1}`, content: "Sample content" }],
      };
    });
  
    // Sort anchors by position before setting them in state
    updatedAnchors.sort((a, b) => a.position - b.position);
    setAnchors(updatedAnchors);
  }, [anchorsByProject, anchorsObjectivesByProject]);

  useEffect(() => {
    console.log('All objectives by anchor and project ID:', objectivesByProjectAndAnchor);
  }, [objectivesByProjectAndAnchor]);

  useEffect(() => {
    console.log('Updated anchors:', anchors);
  }, [anchors, anchorsByProject]);

  const moveCard = (cardIndex, sourceAnchorId, targetAnchorId) => {
    const cardToMove = anchors
      .find(anchor => anchor.id === sourceAnchorId)
      .cards[cardIndex];

    const updatedAnchors = anchors.map(anchor => {
      if (anchor.id === sourceAnchorId) {
        return { ...anchor, cards: anchor.cards.filter((_, index) => index !== cardIndex) };
      }
      if (anchor.id === targetAnchorId) {
        return { ...anchor, cards: [...anchor.cards, cardToMove] };
      }
      return anchor;
    });

    setAnchors(updatedAnchors);
    updateObjective(cardToMove.id, null, null, null, targetAnchorId, null, null);
  };

  const addAnchor = async () => {
    const newAnchor = {
      id: `anchor-${anchors.length + 1}`,
      name: newStepName,
      position: anchors.length + 1,
      type: newStepType,
      cards: [],
    };
    setAnchors([...anchors, newAnchor]);
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    createAnchor(newStepName, props.relatedProject, currentOrgId, newAnchor.position, newStepType, null);
    
  };

  const [newStepName, setNewStepName] = useState(null); 

  const handleStepNameChange = (e) => {
    setNewStepName(e.target.value);
  }

  const [newStepType, setNewStepType] = useState(null); 

  const handleStepTypeChange = (e) => {
    setNewStepType(e.target.value);
  }

  const [newRelatedPath, setNewRelatedPath] = useState(null); 

  const handleRelatedPathChange = (e) => {
    setNewRelatedPath(e.target.value);
  }

  const milestones = [
    {
      label: "Milestone 1",
      dates: ["2024-12-01", "2024-12-05"], // Start and end date
    },
    {
      label: "Milestone 2",
      dates: ["2024-12-10", "2024-12-15"],
    },
    {
      label: "Milestone 3",
      dates: ["2024-12-20", "2024-12-25"],
    },
    {
      label: "Milestone 4",
      dates: ["2024-12-05", "2024-12-10"],
    },
  ];

  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const elementId = 'objectiveAnchorCard';

  const handleRefresh = async () => {
    console.log("Refreshing objective anchors data");
    const currentUserId = localStorage.getItem("fxLocalUserID");
    
    // Call your fetch functions here
    //await fxAPIFetchComStatusTaskByUserId(currentUserId);
    //await fxAPIFetchComStatusObjectiveSubjectiveByUserAssignedId(currentUserId);
    //await fxAPIFetchComStatusObjectiveSubjectiveByRequestorData(currentUserId);

    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchAnchorsByProjectId(props.relatedProject);
    fxAPIFetchObjectivesByProjectAndAnchorId(props.relatedProject, '1');
    fxAPIFetchAnchorsObjectivesByProjectId(props.relatedProject);
    fxAPIFetchPathsByProjectId(props.relatedProject);
  
    resetElementRefresh(elementId); // Reset the refresh flag after refresh is complete
  };

  // Effect to handle refresh logic when the flag changes
  useEffect(() => {
    if (elements[elementId]?.refresh) {
      handleRefresh(); // Trigger the refresh when the flag is set
    }
  }, [elements[elementId]?.refresh]); // Dependency on refresh flag

  return (
    <>
    {/* 
      <div>
        <h3>Test Anchor List</h3>
        {anchorsByProject.map((anchorItem) => (
          <div key={anchorItem.anchor_id}>{anchorItem.anchor_id}</div>
        ))}
        <h3>Test Objectives by Anchor Point 1</h3>
        {objectivesByProjectAndAnchor.map((objectiveItem) => (
          <div key={objectiveItem.objective_id}>
            {objectiveItem.objective_id} {objectiveItem.name}
          </div>
        ))}
        <h3>Test Anchors Objectives by Project View</h3>
        {anchorsObjectivesByProject.map((anchorObjectiveItem) => (
          <div key={anchorObjectiveItem.anchor_id}>
            {anchorObjectiveItem.anchor_id}
          </div>
        ))}
      </div>
      */}
      {/*<CompMilestones milestones={milestones} />*/}
      {anchors.length === 0 &&
        <div>
          <CompHelpBanner bannerMessage="No steps have been defined, please indicate what steps are needed for this playbook" />
        </div>
      }
      <DndProvider backend={HTML5Backend}>
        <div className="board-container">
          {anchors.map(anchor => (
            <Anchor
              key={anchor.id}
              anchor={anchor}
              cards={anchor.cards}
              moveCard={moveCard}
              relatedProject={props.relatedProject}
              fetchUIAssetsByAnchorId={fxAPIFetchUIAssetsByAnchorId}
            />
          ))}

          <div className="anchor-container">
            {/*Test States: {newStepName} {newStepType}*/}
            <div>
              <TextField id="outlined-basic" label="Step Name" variant="outlined" value={newStepName} onChange={handleStepNameChange} />
            </div>
            <br />
            <div>
              <FormControl fullWidth>
                <InputLabel id="step-type-label">Add to Path (if flow step)</InputLabel>
                <Select
                  labelId="step-type-label"
                  id="step-type"
                  value={newRelatedPath}
                  label="Related Path"
                  onChange={handleRelatedPathChange}
                >
                  {pathsByProjectId.map((path) => (
                    <MenuItem key={path.path_id} value={path.path_id}>{path.path_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <br />
            <div>
              <FormControl fullWidth>
                <InputLabel id="step-type-label">Type of Step</InputLabel>
                <Select
                  labelId="step-type-label"
                  id="step-type"
                  value={newStepType}
                  label="Type of Step"
                  onChange={handleStepTypeChange}
                >
                  <MenuItem value="start">Start</MenuItem>
                  <MenuItem value="assignment">Assignment (UI)</MenuItem>
                  <MenuItem value="decision">Decision</MenuItem>
                  <MenuItem value="system-action">System Action</MenuItem>
                  <MenuItem value="integration">Integration</MenuItem>
                  <MenuItem value="case-action">Case Action</MenuItem>
                  <MenuItem value="sub-process">Sub Process</MenuItem>
                  <MenuItem value="end">End</MenuItem>
                </Select>
              </FormControl>
            </div>
            <br />
            <Button onClick={addAnchor} variant="outlined">Add New Step</Button>
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default CompObjectiveAnchors;