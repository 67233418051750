import axios from 'axios';

const createActivityHistory = async (
  message, 
  related_object_type, 
  related_object, 
  category, 
  tags, 
  metadata, 
  related_org, 
  related_project, 
  authoring_user, 
  source, 
  socket, // Pass socket to emit event
  emitHistory // Flag to control socket emission
) => {
  try {
    // Generate activity history ID
    const activityHistoryId = generateUUID();

    if (!activityHistoryId || !related_org) {
      console.warn('Missing required parameters: activityHistoryId and related_org.');
      return;
    }

    console.log(`Creating activity history for orgId: ${related_org}`);

    // Send request to create activity history on the server
    try {
      const response = await axios.post('/fxapi/activity-history/create', {
        activity_history_id: activityHistoryId,
        message,
        related_object_type,
        related_object,
        category,
        tags,
        metadata,
        related_org,
        related_project,
        authoring_user,
        source
      });

      console.log('API response received:', response.data);

      // Emit the event if flag is true
      if (emitHistory && socket) {
        socket.emit('activity-history-created', response.data);
        console.log('Socket message emitted: activity-history-created');
      }
    } catch (apiError) {
      console.error('Error creating activity history:', apiError);
      // Handle the error but allow socket emission to proceed
      if (emitHistory && socket) {
        socket.emit('activity-history-created', {
          activity_history_id: activityHistoryId,
          message,
          related_object_type,
          related_object,
          category,
          tags,
          metadata,
          related_org,
          related_project,
          authoring_user,
          source,
        });
        console.log('Socket message emitted (error case): activity-history-created');
      }
    }

  } catch (error) {
    console.error('Unexpected error in createActivityHistory:', error);
  }
};


  const getActivityHistoryByOrgId = async (orgId, setError, setActivityHistoryByOrgData) => {
    setError(null);
    setActivityHistoryByOrgData([]); // Reset Anchors data to an empty array
  
    if (!orgId) {
      setError('orgId parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching activity history data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/activity-history/activity-history-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);
  
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting activity history by org data:', items);
      setActivityHistoryByOrgData(items); // Correctly update the state with received data
      console.log('Updated activity history by org data:', items);
    } catch (error) {
      console.error('Error fetching activity history by org data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch activity history by org data');
    }
  };

    // Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  export { createActivityHistory, getActivityHistoryByOrgId };