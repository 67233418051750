import React, { useState, useEffect } from 'react';
import './CompJourneyMap.css';
import { getPathsByProjectId } from '../fxAPI/fxPathsAPI';
import { getAnchorsPersonasPathsByPathId, getAnchorsPathsByPathId, getAnchorsPersonasByAnchorId } from '../fxAPI/fxAnchorsAPI';
import CompJourneyCard from './CompJourneyCard';
import CompSwimlaneCard from './CompSwimlaneCard';
import CompUserPersonaAvatar from './CompUserPersonaAvatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CompCollapsibleContainer from './CompCollapsibleContainer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';
import CompJourneyAddMenu from './CompJourneyAddMenu';

const CompJourneyMap = (props) => {

    const [error, setError] = useState(null);

  /* PATHS BY PROJECT ID */
    const [pathsByProjectId, setPathsByProjectData] = useState([]); // Initialize as an array
    const [currentPath, setCurrentPath] = useState(null);

    /* get user personas by orgId */
    const fxAPIFetchPathsByProjectId = async (projectId) => {
      await getPathsByProjectId(projectId, setError, setPathsByProjectData);
      console.log('all paths by project Id');
      console.dir(pathsByProjectId);
    };

    /* ANCHORS PERSONAS PATHS BY PATH ID */
      const [anchorsPersonasPathsByPathId, setAnchorsPersonasPathsByPathData] = useState([]); // Initialize as an array
      /* get user personas by orgId */
      const fxAPIFetchAnchorsPersonasPathsByPathId = async (pathId) => {
        await getAnchorsPersonasPathsByPathId(pathId, setError, setAnchorsPersonasPathsByPathData);
        console.log('all anchors personas paths by path Id');
        console.dir(anchorsPersonasPathsByPathId);
      };

    /* ANCHORS PERSONAS BY ANCHOR ID */
    const [anchorsPersonasByAnchorId, setAnchorsPersonasByAnchorData] = useState([]); // Initialize as an array
    /* get user personas by orgId */
    const fxAPIFetchAnchorsPersonasByAnchorId = async (anchorId) => {
      await getAnchorsPersonasByAnchorId(anchorId, setError, setAnchorsPersonasByAnchorData);
      console.log('all anchors personas by anchor Id');
      console.dir(anchorsPersonasByAnchorId);
    };

    useEffect(() => {
        const fetchData = async () => {
            // Fetch paths by project ID
            console.log('Fetching path data for projectId:', props.relatedProject);
            await fxAPIFetchPathsByProjectId(props.relatedProject);
    
            // Fetch anchor persona paths by path ID
            console.log('Fetching anchor persona path data for pathId:', currentPath);
            await fxAPIFetchAnchorsPersonasPathsByPathId(currentPath);
    
            console.log('Data fetching complete.');

            anchorsPersonasPathsByPathId.forEach((persona) => {
                console.log('Processing persona:', persona);
            });

        };
    
        fetchData();
    }, [props.relatedProject, currentPath]);

    useEffect(() => {
        console.log('caught update of anchorsPersonasPathsByPathId');
        anchorsPersonasPathsByPathId.forEach((persona) => {
            console.log('Processing persona:', persona);
            fxAPIFetchAnchorsPersonasByAnchorId(persona.anchor_id);
        });
    }, [anchorsPersonasPathsByPathId]);

    const [uniqueUserPersonas, setUniqueUserPersonas] = useState([]);

    useEffect(() => {
        console.log('Caught update of anchorsPersonasByAnchorId');

        const updatedPersonas = [...uniqueUserPersonas];

        anchorsPersonasByAnchorId.forEach((personaByAnchor) => {
            const { user_persona_id, user_persona_view_name, related_avatar } = personaByAnchor;

            console.log('Processing persona by anchor:', personaByAnchor);

            // Check if user_persona_id already exists in uniqueUserPersonas
            const exists = updatedPersonas.some(
                (persona) => persona.user_persona_id === user_persona_id
            );

            if (!exists) {
                // Add new persona to the list
                updatedPersonas.push({ user_persona_id, user_persona_view_name, related_avatar });
            }
        });

        setUniqueUserPersonas(updatedPersonas);
    }, [anchorsPersonasByAnchorId]);

    useEffect(() => {
        console.log('Unique User Personas:', uniqueUserPersonas);
    }, [uniqueUserPersonas]);



    {/*

    const compileUserPersonasForAnchors = async (anchorId) => {
        console.log('Starting compileUserPersonasForAnchors with anchorId:', anchorId);
    
        // Fetch the personas by anchor ID
        await fxAPIFetchAnchorsPersonasByAnchorId(anchorId);
    
        console.log('anchorsPersonasByAnchorId data after fetch:', anchorsPersonasByAnchorId);
    
        // Ensure data is loaded and proceed
        if (anchorsPersonasByAnchorId.length === 0) {
            console.log('No data found in anchorsPersonasByAnchorId for anchorId:', anchorId);
            return [];
        }
    
        // Filter for matching anchor_id and extract unique user_persona data
        const uniquePersonas = new Map();
    
        anchorsPersonasByAnchorId.forEach((persona) => {
            console.log('Processing persona:', persona);
    
            if (persona.anchor_id === anchorId) {
                console.log('Matching anchor_id found for persona:', persona);
    
                if (!uniquePersonas.has(persona.user_persona_id)) {
                    console.log('Adding unique persona:', {
                        user_persona_id: persona.user_persona_id,
                        user_persona_view_name: persona.user_persona_view_name,
                    });
    
                    uniquePersonas.set(persona.user_persona_id, {
                        user_persona_id: persona.user_persona_id,
                        user_persona_view_name: persona.user_persona_view_name,
                    });
                } else {
                    console.log(
                        `Persona with user_persona_id: ${persona.user_persona_id} already exists, skipping.`
                    );
                }
            } else {
                console.log(
                    `anchor_id mismatch: persona.anchor_id (${persona.anchor_id}) !== ${anchorId}`
                );
            }
        });
    
        // Convert the Map to an array of unique rows
        const uniquePersonasArray = Array.from(uniquePersonas.values());
        console.log('Final uniquePersonasArray:', uniquePersonasArray);
    
        return uniquePersonasArray;
    };

     
    

    useEffect(() => {
        const fetchData = async () => {
            // Fetch paths by project ID
            console.log('Fetching path data for projectId:', props.relatedProject);
            await fxAPIFetchPathsByProjectId(props.relatedProject);
    
            // Fetch anchor persona paths by path ID
            console.log('Fetching anchor persona path data for pathId:', currentPath);
            await fxAPIFetchAnchorsPersonasPathsByPathId(currentPath);
    
            console.log('Data fetching complete.');
        };
    
        fetchData();
    }, [props.relatedProject, currentPath]);

    useEffect(() => {
        const compilePersonas = async () => {
            console.log('anchorsPersonasPathsByPathId state:', anchorsPersonasPathsByPathId);
    
            if (anchorsPersonasPathsByPathId.length === 0) {
                console.log('No data in anchorsPersonasPathsByPathId. Skipping compilation.');
                return;
            }
    
            const allUniquePersonas = [];
            for (const record of anchorsPersonasPathsByPathId) {
                console.log('Processing record for anchorId:', record.anchor_id);
                const personas = await compileUserPersonasForAnchors(record.anchor_id);
                if (personas) {
                    allUniquePersonas.push(...personas);
                }
            }
    
            console.log('All unique personas compiled:', allUniquePersonas);
        };
    
        compilePersonas();
    }, [anchorsPersonasPathsByPathId]);

    */}

    const handlePathChange = (event) => {
        setCurrentPath(event.target.value);
    };
    

  return (
    <div>
        <div className='fx-comp-journey-select-container'>
            <FormControl fullWidth>
                <InputLabel id="journey-label">Journey</InputLabel>
                <Select
                labelId="journey-label"
                id="journey"
                value={currentPath}
                label="Journey"
                onChange={handlePathChange}
                autoWidth="true"
                sx={{ maxWidth: 300}}
                >
                <MenuItem>Select journey...</MenuItem>
                {pathsByProjectId.map((path) => (
                <MenuItem value={path.path_id}>{path.name}</MenuItem>
                ))}
                </Select>
            </FormControl>
        </div>
    {!currentPath &&
        <div>
            <Alert severity="info">Select a Journey to continue</Alert>
        </div>
    }
    {currentPath &&
    <>
      {/*<div>Journey Map for project {props.relatedProject}</div>*/}
      <div className='fx-comp-journey-header-row'>
        <div className='fx-comp-journey-header-item'>
            User Personas
        </div>
      
            {anchorsPersonasPathsByPathId.map((anchor) => (
                <div key={anchor.anchor_id} className='fx-comp-journey-header-item'>
                {anchor.anchor_view_name}
                </div>
            ))}
        </div>
        {/* end header row */}
        {uniqueUserPersonas.map((personaJourneyRow) => (
            <div key={personaJourneyRow.user_persona_id} className='fx-comp-journey-path-row'>
                <div className='fx-comp-journey-header-item'>
                    {personaJourneyRow.user_persona_view_name}
                    <CompUserPersonaAvatar avatarId={personaJourneyRow.related_avatar} />
                </div>
                {anchorsPersonasPathsByPathId.map((anchor) => (
                    <div className='fx-comp-journey-header-item'>
                        {/*{anchor.anchor_view_name}*/}
                        {/*<CompJourneyCard />*/}
                        <CompSwimlaneCard anchorType={anchor.type} personaName={personaJourneyRow.user_persona_view_name} anchorId={anchor.anchor_id} userPersonaId={personaJourneyRow.user_persona_id} />
                    </div>
                ))}
            </div>
        ))}

        <div>
            <div className='fx-comp-swimlane-flow-detail-path-row'>
                <div className="fx-comp-swimlane-flow-detail-row-starter">
                Add
                </div>
                {anchorsPersonasPathsByPathId.map((anchor) => (
                <div key={anchor.anchor_id} className="fx-comp-swimlane-flow-detail-row-item">
                        <CompJourneyAddMenu relatedAnchorId={anchor.anchor_id} relatedProjectId={props.relatedProject} />          
                </div>
                ))}
            </div>
        </div>

        <div>
          <CompCollapsibleContainer containerHeading="Devices">
            <div className='fx-comp-swimlane-flow-detail-path-row'>
            <div className="fx-comp-swimlane-flow-detail-row-starter">
              Title
            </div>
            {anchorsPersonasPathsByPathId.map((anchor) => (
              <div key={anchor.anchor_id} className="fx-comp-swimlane-flow-detail-row-item">
                      <div>{anchor.anchor_view_name}</div>            
              </div>
              ))}
            </div>
          </CompCollapsibleContainer>
        </div>
        <div>
          <CompCollapsibleContainer containerHeading="Components">
            <div>
              Component Details ...
            </div>
          </CompCollapsibleContainer>
        </div>
        {/*
        <div>
          <CompCollapsibleContainer containerHeading="Design Sessions">
            <div>
              Design Sessions Details...
            </div>
          </CompCollapsibleContainer>
        </div>
        */}
        </>
        }
    </div>
    
  );
};

export default CompJourneyMap;