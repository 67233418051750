import axios from 'axios';

const createDesignSession = async (name, description, lead_user, participants, status, related_project, related_org, related_anchor, related_object_type, related_object) => {
    const setError = (message) => {
      console.error(message);
    };
  
    const setSuccessMessage = (message) => {
      console.log(message);
    };
  
    setError(null);
    setSuccessMessage(null);

    const design_session_id = generateUUID();
  
    if (!name) {
      setError('Name is required');
      return;
    }
  
    try {
      console.log(`Creating new design session with name: ${name} and related organization: ${related_org}`);
  
      const response = await axios.post('/fxapi/design-sessions/create', {
        design_session_id,
        name,
        description,
        lead_user,
        participants,
        status,
        related_project,
        related_org,
        related_anchor,
        related_object_type,
        related_object
      });
  
      console.log('API response received:', response.data);
      setSuccessMessage('Design Session created successfully');
    } catch (error) {
      console.error('Error creating design session:', error);
      setError(error.response ? error.response.data : 'Failed to create design session');
    }
  };

  const updateDesignSession  = async (designSessionId, name, description, lead_user, participants, status, related_project, related_org, related_anchor, related_object_type, related_object) => {

    try {
      const response = await axios.put(`/fxapi/milestones/update/${designSessionId}`, {
        design_session_id: designSessionId,
        name,
        description,
        lead_user,
        participants,
        status,
        related_project,
        related_org,
        related_anchor,
        related_object_type,
        related_object
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  const deleteDesignSession = async (designSessionId, setError, setDeleteResponse) => {
    setError(null);
    setDeleteResponse(null); // Reset delete response
  
    if (!designSessionId) {
      setError('designSession ID parameter is required');
      return;
    }
  
    try {
      console.log(`Deleting designSession with ID: ${designSessionId}`);
      const response = await axios.delete(`/fxapi/design-sessions/delete/${designSessionId}`);
      console.log('API response received:', response.data);
  
      // Convert response data to plain text
      const successMessage = `Successfully deleted designSession with ID: ${designSessionId}`;
      setDeleteResponse(successMessage); // Update the state with plain text
      console.log('Updated delete response:', successMessage);
    } catch (error) {
      console.error('Error deleting designSession:', error);
  
      // Convert error to plain text
      const errorMessage = error.response ? error.response.data : 'Failed to delete designSession';
      setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
    }
  };

  const getDesignSessionsByAnchorId = async (anchorId, setError, setDesignSessionsByAnchorData) => {
    setError(null);
    setDesignSessionsByAnchorData([]); // Reset Objectives data to an empty array
  
    if (!anchorId) {
      setError('anchorId parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching Design Sessions data for anchorId: ${anchorId}`);
      const response = await axios.get('/fxapi/design-sessions/design-sessions-by-anchor', {
        params: { anchorId }
      });
      console.log('API response received:', response.data.items);
  
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting design sessions data:', items);
      setDesignSessionsByAnchorData(items); // Correctly update the state with received data
      console.log('Updated design sessions data:', items);
    } catch (error) {
      console.error('Error fetching design sessions data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch design sessions data');
    }
  };

  const getDesignSessionsByOrgId = async (orgId, setError, setDesignSessionsByOrgData) => {
    setError(null);
    setDesignSessionsByOrgData([]); // Reset Objectives data to an empty array
  
    if (!orgId) {
      setError('orgId parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching Design Sessions data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/design-sessions/design-sessions-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);
  
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting design sessions data:', items);
      setDesignSessionsByOrgData(items); // Correctly update the state with received data
      console.log('Updated design sessions data:', items);
    } catch (error) {
      console.error('Error fetching design sessions data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch design sessions data');
    }
  };

  const getDesignSessionById = async (designSessionId, setError, setDesignSessionByIdData) => {
    setError(null);
    setDesignSessionByIdData([]); // Reset Objectives data to an empty array
  
    if (!designSessionId) {
      setError('designSessionId parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching Design Sessions data for designSessionId: ${designSessionId}`);
      const response = await axios.get('/fxapi/design-sessions/design-session-by-id', {
        params: { designSessionId }
      });
      console.log('API response received:', response.data.items);
  
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting design sessions data:', items);
      setDesignSessionByIdData(items); // Correctly update the state with received data
      console.log('Updated design sessions data:', items);
    } catch (error) {
      console.error('Error fetching design sessions data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch design sessions data');
    }
  };

  const getDesignSessionsByProjectId = async (projectId, setError, setDesignSessionsByProjectData) => {
    setError(null);
    setDesignSessionsByProjectData([]); // Reset Objectives data to an empty array
  
    if (!projectId) {
      setError('projectId parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching Design Sessions data for projectId: ${projectId}`);
      const response = await axios.get('/fxapi/design-sessions/design-sessions-by-project', {
        params: { projectId }
      });
      console.log('API response received:', response.data.items);
  
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting design sessions data:', items);
      setDesignSessionsByProjectData(items); // Correctly update the state with received data
      console.log('Updated design sessions data:', items);
    } catch (error) {
      console.error('Error fetching design sessions data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch design sessions data');
    }
  };

    // Helper function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

  export { createDesignSession, updateDesignSession, deleteDesignSession, getDesignSessionsByAnchorId, getDesignSessionsByOrgId, getDesignSessionById, getDesignSessionsByProjectId };