import React, { useState, useEffect } from 'react';
import './CompBusinessValueObjectiveTree.css';
import { getObjectivesByBusinessValueId } from '../fxAPI/fxObjectivesAPI';
import CompCollapsibleContainer from './CompCollapsibleContainer';
import { Button } from '@mui/material';
import CompBusinessValueSubjectiveList from './CompBusinessValueSubjectiveList';
import CompSubjectivePanel from './CompSubjectivePanel';



const CompBusinessValueObjectiveTree = (props) => {

    const [error, setError] = useState(null);

    /* OBJECTIVES BY BUSINESS VALUE ID */
    const [objectivesByBusinessValueId, setObjectivesByBusinessValueByData] = useState([]); // Initialize as an array
    /* get user personas by orgId */
    const fxAPIFetchBusinessValueById = async (businessValueId) => {
    await getObjectivesByBusinessValueId(businessValueId, setError, setObjectivesByBusinessValueByData);
    console.log('objectives by business value Id');
    console.dir(objectivesByBusinessValueId);
    };

    useEffect(() => {
          //const currentOrgId = localStorage.getItem("fxLocalOrgID");
          fxAPIFetchBusinessValueById(props.businessValueObject[0].business_value_id);
          console.log('objectives by business value Id');
          console.dir(objectivesByBusinessValueId);
        }, []);

  return (
    <div>
      
      <h3>Objectives</h3>
      {/*{props.businessValueObject[0].name}*/}
      {objectivesByBusinessValueId.map((businessValueObjective) => (
        <CompSubjectivePanel relatedObjective={businessValueObjective.objective_id} panelName={businessValueObjective.name} />
                ))}
        <div className='fx-comp-business-value-tree-link-button-wrap'><Button variant="outlined">Link an Objective</Button></div>
    </div>
  );
};

export default CompBusinessValueObjectiveTree;