import React from 'react';
import './CompCollapsibleContainer.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const CompCollapsibleContainer = (props) => {

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            flexDirection: 'row-reverse', // This moves the expand icon to the left
            '& .MuiAccordionSummary-expandIconWrapper': {
              marginRight: 0, // Reset margin when on the left side
              marginLeft: 'auto', // Push the icon to the left
            },
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {props.containerHeading}
            {props.itemCount > 0 && 
              <div className='fx-comp-collapsible-container-item-count-wrap'>{props.itemCount}</div>
            }
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {props.children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CompCollapsibleContainer;