import React, { useEffect, useState } from "react";
import './CompAccessibilityAutomatedChecksList.css';
import axe from "axe-core";
import CompCollapsibleContainer from "./CompCollapsibleContainer";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import IconButton from '@mui/material/IconButton';
import CompAccessibilityListItem from "./CompAccessibilityListItem";


const CompAccessibilityAutomatedChecksList = (props) => {

  const [rules, setRules] = useState({});
  const categoryHeaders = {
    "cat.semantics": "Semantics",
    "cat.color": "Color and Contrast",
    "cat.aria": "ARIA",
    "cat.keyboard": "Keyboard Accessibility",
    "cat.forms": "Forms and Inputs",
    "cat.language": "Language and Text",
    "cat.text-alternatives": "Text Alternatives",
    "cat.name-role-value": "Name, Role, Value",
    "cat.time-and-media": "Time and Media",
    "cat.structure": "Structure",
    "cat.parsing": "Parsing",
    "cat.sensory-and-visual-cues": "Sensory and Visual Cues",
    "cat.tables": "Tables"
    // Add more as needed...
  };

  useEffect(() => {
    const fetchedRules = axe.getRules();
    const groupedRules = groupRulesByCategory(fetchedRules);
    setRules(groupedRules);
  }, []);

  const groupRulesByCategory = (rules) => {
    const grouped = {};
    rules.forEach((rule) => {
      const category = rule.tags.find((tag) => tag.startsWith("cat."));
      if (category) {
        if (!grouped[category]) {
          grouped[category] = [];
        }
        grouped[category].push(rule);
      }
    });
    return grouped;
  };

  return (
    <div>
      <h2>Automated Checks</h2>
      {Object.keys(rules).length > 0 ? (
        Object.entries(rules).map(([category, rulesList]) => (
          <div key={category}>
            <CompCollapsibleContainer containerHeading={categoryHeaders[category] || category} itemCount={rulesList.length}>
                {rulesList.map((rule) => (
                  <div key={rule.id}>
                    <CompAccessibilityListItem guidelineText={rule.description} helpText={rule.help} subText={rule.tags.join(", ")} />
                  </div>
                ))}
            </CompCollapsibleContainer>            
          </div>
        ))
      ) : (
        <p>Loading rules...</p>
      )}
    </div>
  );
};

export default CompAccessibilityAutomatedChecksList;