import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import './PagePlaybookJourneyMap.css';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompJourneyMap from '../Components/CompJourneyMap';

const PagePlaybookJourneyMap = (props) => {
  
    const params = useParams();

  return (
    <CompPageTempMaster3Panel headingOne="Journey Map">

      <CompJourneyMap relatedProject={params.projectId} /> 
   
    </CompPageTempMaster3Panel>
  );
};

export default PagePlaybookJourneyMap;