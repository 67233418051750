import React from 'react';
import './CompBusinessValueBreakdown.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompDashCardGallery from './CompDashCardGallery';


const CompBusinessValueBreakdown = (props) => {

  return (
    <div>
      <CompDashCardGallery
          galleryType="business-value"
        />
    </div>
  );
};

export default CompBusinessValueBreakdown;