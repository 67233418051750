import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import './CompModalJourneyAssignUI.css';
import { updateAnchor } from '../fxAPI/fxAnchorsAPI.js';
import { getUIAssetsByProjectId } from '../fxAPI/fxUIAssetsAPI';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const CompModalJourneyAssignUI = (props) => {

  const [error, setError] = useState(null);

const [open, setOpen] = React.useState(false);

/* UI Assets */
  const [uiAssetsByProjectId, setUIAssetsByProjectData] = useState([]); // Initialize as an array
  /* get UI Assets by projectId */
  const fxAPIFetchUIAssetsByProjectId = async (projectId) => {
    await getUIAssetsByProjectId(projectId, setError, setUIAssetsByProjectData);
    console.log('all ui assets by project Id');
    console.dir(uiAssetsByProjectId);
  };

  const handleUpdateAnchor = (primaryUIAsset) => {
    const relatedAnchor = props.relatedAnchorId;
    updateAnchor(relatedAnchor, null, null, null, null, null, primaryUIAsset);
    console.log('anchor: ' + props.relatedAnchorId + ' updated with new primary ui asset: ' + primaryUIAsset);
    handleClose();
  };

  useEffect(() => {
      const currentProject = props.relatedProjectId;
      if(currentProject) {
        fxAPIFetchUIAssetsByProjectId(currentProject);
      }
    }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div onClick={handleClickOpen}>Assign Primary UI</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Assign Primary UI
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className='fx-comp-journey-assign-ui-items-container'>
          {uiAssetsByProjectId.map((uiAssetItem) => (
            <div className='fx-comp-journey-assign-ui-item'>
                <div className='fx-comp-journey-assign-ui-item-img-portion'>
                    <img src={uiAssetItem.img_thumb_url} width="200px" height="200px" />
                </div>
                <div className='fx-comp-journey-assign-ui-item-img-title-portion'>
                    <div><strong>{uiAssetItem.name}</strong></div>
                    <div><Button variant='outlined' onClick={() => handleUpdateAnchor(props.relatedAnchorId, uiAssetItem.ui_asset_id)}>Assign as Primary UI</Button></div>
                </div>
            </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default CompModalJourneyAssignUI;