import React, { useState, useEffect } from 'react';
import './CompAnchorToolbar.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import EditIcon from '@mui/icons-material/Edit';
import CompUIAssetAnchorModal from './CompUIAssetAnchorModal';
import DeleteIcon from '@mui/icons-material/Delete';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { getObjectivesByProjectIdAndAnchorId } from '../fxAPI/fxObjectivesAPI.js';
import { getUserPersonasByOrgId } from '../fxAPI/fxUserPersonasAPI.js';
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI.js';
import { getAnchorsPersonasByAnchorId } from '../fxAPI/fxAnchorsAPI.js';
import CompCRUDModal from './CompCRUDModal';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Popover from '@mui/material/Popover';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CompUserPersonaAvatar from './CompUserPersonaAvatar.jsx';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CompLinkObjectToInt from './CompLinkObjectToInt';

const CompAnchorToolbar = (props) => {
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  const [linkType, setLinkType] = useState(null);
  const [modalComponentData, setModalComponentData] = useState([]);
  const [assignedUserPersona, setAssignedUserPersona] = useState(props.userAssigned);
  const [assignedPath, setAssignedPath] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  /* USER PERSONAS */
  const [userPersonasByOrgId, setUserPersonasByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUserPersonasByOrgId = async (orgId) => {
    await getUserPersonasByOrgId(orgId, setError, setUserPersonasByOrgData);
    console.log('all user personas by org Id');
    console.dir(userPersonasByOrgId);
  };

  /* ANCHORS PERSONAS */
  const [anchorPersonasByAnchorId, setAnchorPersonasByAnchorIdData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchAnchorsPersonasByAnchorId = async (anchorId) => {
    await getAnchorsPersonasByAnchorId(anchorId, setError, setAnchorPersonasByAnchorIdData);
    console.log('all user personas by anchor Id');
    console.dir(anchorPersonasByAnchorId);
  };

  const handleChange = (event) => {
    console.log('Selected User Persona ID:', event.target.value); // Debugging log
    setAssignedUserPersona(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    //console.log('user assignment for: ' + props.componentType);
    //console.dir(props.componentData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateAnchorWithPersona = () => {
    console.log('called handle update anchor' + props.anchorId + ' with persona: ' + assignedUserPersona + ', no data sent yet');
    handleCreateAnchorUserPersonaObjectLink();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openModal = (itemType, currentCRUDMethod, currentModalComponentData, currentModalTitle, currentLinkType) => {
    setItemType(itemType);
    setCRUDMethod(currentCRUDMethod);
    setModalComponentData(currentModalComponentData);
    setIsOpen(true);
    setActionModalTitle(currentModalTitle);
    setLinkType(currentLinkType);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  /* OBJECTIVES BY PROJECT AND ANCHOR */
  const [objectivesByProjectAndAnchor, setObjectivesByProjectAndAnchorData] = useState([]);

  const fxAPIFetchObjectivesByProjectAndAnchorId = async (projectId, anchorId) => {
    await getObjectivesByProjectIdAndAnchorId(projectId, anchorId, setError, setObjectivesByProjectAndAnchorData);
  };

  const handleDelete = async () => {
    // Fetch objectives
    await fxAPIFetchObjectivesByProjectAndAnchorId(props.relatedProject, props.anchorId);

    // Check results and log accordingly
    if (objectivesByProjectAndAnchor.length > 0) {
      console.log(
        `Warning: Cannot delete anchor ID ${props.anchorId} for project ${props.relatedProject} as it has associated objectives.`
      );
    } else {
      console.log(
        `Approve: Anchor ID ${props.anchorId} for project ${props.relatedProject} can be safely deleted.`
      );
    }
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchUserPersonasByOrgId(currentOrgId);
    fxAPIFetchAnchorsPersonasByAnchorId(props.anchorId);
  }, []);

  const handleCreateAnchorUserPersonaObjectLink = async () => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
   const initial_object_id = props.anchorId; // update needed
   const initial_object_type = "anchor";
   const linking_object_id = assignedUserPersona; // update needed
   const linking_object_type = "user-persona";
   const relationship_type = ""; // update needed
   const related_project = props.relatedProject;
   const related_org = currentOrgId;


    await createObjectLink(initial_object_id, initial_object_type, linking_object_id, linking_object_type, relationship_type, related_project, related_org);
  };

  const handleCreateAnchorPathObjectLink = async () => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
   const initial_object_id = props.anchorId; // update needed
   const initial_object_type = "anchor";
   const linking_object_id = assignedUserPersona; // update needed
   const linking_object_type = "path";
   const relationship_type = ""; // update needed
   const related_project = props.relatedProject;
   const related_org = currentOrgId;


    await createObjectLink(initial_object_id, initial_object_type, linking_object_id, linking_object_type, relationship_type, related_project, related_org);
  };

  const localEnv = localStorage.getItem('fxEnv');

  return (
    <>
    <div className="fx-anchor-toolbar-personas-container">
              {anchorPersonasByAnchorId.map((userPersonaByAnchor) => (
                <div key={userPersonaByAnchor.link_id}>
                  <CompUserPersonaAvatar avatarId={userPersonaByAnchor.related_avatar} />
                </div>
              ))}
    </div>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="fx-user-assign-popover-container"
        PaperProps={{
          style: {
            padding: '16px', // Adjust padding
            minWidth: '200px' // Adjust min-width
          },
        }}
      >
        <div className="fx-user-assign-popover-row">
          <FormControl fullWidth>
            <InputLabel id="user-persona-assigned-label">Add User Persona</InputLabel>
            <Select
              labelId="user-persona-assigned-label"
              id="user-persona-assigned"
              value={assignedUserPersona}
              label="Assign User Persona To"
              onChange={handleChange}
            >
              {userPersonasByOrgId.map((userPersona) => (
                <MenuItem key={userPersona.user_persona_id} value={userPersona.user_persona_id}>
                  {`${userPersona.name} | ${userPersona.role || ""}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={handleUpdateAnchorWithPersona}>Assign</Button>
        </div>
      </Popover>
    <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={actionModalTitle} relatedProject={props.relatedProject} itemType={itemType} crudMethod={crudMethod} modalComponentData="" relatedAnchorId={props.anchorId} linkType={linkType}></CompCRUDModal>
    <div className="fx-comp-anchor-toolbar-outer">
      <EditIcon />
      <div onClick={() => openModal("objective", "create", null, "Create Objective", null)}><PostAddIcon /></div>
      <CompUIAssetAnchorModal anchorId={props.anchorId} />
      <div aria-describedby={id} onClick={handleClick}><PersonAddIcon /></div>
      <div onClick={handleDelete}>
        <DeleteIcon />
      </div>
      <div onClick={() => openModal("object-link", "create", null, "Link Step to Path(s)", "anchor-path")}>
        <AccountTreeIcon />
      </div>
      {localEnv !== 'production' &&
      <div>
        <CompLinkObjectToInt />
      </div>
      }
    </div>
    </>
  );
};

export default CompAnchorToolbar;